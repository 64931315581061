// firebase.js code

// Import necessary functions from Firebase
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // Import getStorage

// Your Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAFafQrZp-AxZjlAjiN_tkp_JQKquH1QnI',
  authDomain: 'constantinepr-portal.firebaseapp.com',
  databaseURL: 'https://constantinepr-portal-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'constantinepr-portal',
  storageBucket: 'constantinepr-portal.firebasestorage.app',
  messagingSenderId: '502608866164',
  appId: '1:502608866164:web:902d0fb1c180e929e41a21',
  measurementId: ''
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get authentication and Firestore instances
const auth = getAuth(app);
const database = getFirestore(app);
const storage = getStorage(app); // Initialize Firebase Storage

// Export the instances
export { auth, database, storage, app as default }; // Export storage