import React, { useEffect, useState } from "react";
import { useAuth } from "./AuthContext";
import { FaUser, FaMoneyBill, FaCalendarAlt, FaCog, FaUserCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import DashboardLayout from "../layouts/dashboardlayout";
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import { database } from "../components/Firebase/firebase";
import { format } from "date-fns";
import AdminNotificationBannerDisplay from "../components/AdminNotification/AdminNotificationDisplay";

interface AttendanceRecord {
  date: string;
  checkInTime?: string;
  checkOutTime?: string;
  status: string;
}

const UserDashboard: React.FC = () => {
  const { userName, logout, user } = useAuth();
  const navigate = useNavigate();
  const [attendanceStatus, setAttendanceStatus] = useState<AttendanceRecord | null>(null);
  const [loading, setLoading] = useState(true);
  const [gender, setGender] = useState<string>("");

  // Redirect to login if user is not authenticated
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  // Fetch user's gender
  useEffect(() => {
    const fetchUserGender = async () => {
      if (user) {
        try {
          const userDocRef = doc(database, "users", user.uid);
          const userDoc = await getDoc(userDocRef);
          
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setGender(userData.gender || ""); // Set gender from Firestore
          }
        } catch (error) {
          console.error("Error fetching user gender:", error);
        }
      }
    };

    fetchUserGender();
  }, [user]);

  // Rest of your existing code...
  // (Keep all other useEffect hooks, functions, and the return statement)

  // Fetch today's attendance record
  useEffect(() => {
    const fetchTodaysAttendance = async () => {
      if (user) {
        const today = format(new Date(), "yyyy-MM-dd");
        const q = query(
          collection(database, "attendance"),
          where("userId", "==", user.uid),
          where("date", "==", today)
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const record = querySnapshot.docs[0].data() as AttendanceRecord;
          setAttendanceStatus(record);
        } else {
          setAttendanceStatus(null);
        }
        setLoading(false);
      }
    };

    fetchTodaysAttendance();
  }, [user]);
 
  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
    </div>
    )
}

  return (

    <DashboardLayout title="User Dashboard">
      <h1 className="dashboard-greeting text-2xl font-bold text-text-dark mb-2">
        <span className="block">Hey, <span className="font-light">{userName}!</span></span>  </h1>
        <div className="metrics-summary flex flex-col md:flex-row items-start justify-start gap-4 mb-5">
        {loading ? (
              <p className="text-gray-600 dark:text-gray-400">Loading attendance status...</p>
            ) : attendanceStatus ? (
              <div className="flex flex-col sm:flex-row items-center justify-center gap-2 mt-4">
                <span className="font-bold">Status:</span>
                <div className="metric-badge unusual bg-badge-unusual-bg text-badge-unusual-text px-4 py-2 rounded-full text-sm font-medium"><strong className="ml-1">{attendanceStatus.status}</strong></div>
                {attendanceStatus.checkInTime && (
                
                    <div className="metric-badge unusual bg-badge-unusual-bg text-badge-unusual-text px-4 py-2 rounded-full text-sm font-medium"><strong className="ml-1"> {attendanceStatus.checkInTime}</strong></div>
              
                )}
                {attendanceStatus.checkOutTime && (
                     <div className="metric-badge normal bg-badge-normal-bg text-badge-normal-text px-4 py-2 rounded-full text-sm font-medium">Late <strong className="ml-1">{attendanceStatus.checkOutTime}</strong></div>
                 
                )}
              </div>
            ) : (
              <p className="text-lg text-gray-600 dark:text-gray-400">Your attendance is pending.</p>
            )}
            </div>
            <AdminNotificationBannerDisplay/>            

{/* old dash code heree */}
{/* custom new style */}
<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 pt-10 pb-20">
                        <div className="p-4 rounded-2xl border border-solid border-gray-200">
                            <div className="flex items-center justify-between gap-4">
                                <div className="flex items-center gap-3.5">
                                    <span className="rounded-2xl bg-gray-100 p-3">
                                    <img src={gender === "male" 
                ? "/images/avatar/avatarmale.webp" 
                : "/images/avatar/avatarfemale.webp"} alt="avatar" className="w-8 h-8 rounded-full" />
                                    </span>
                                    <h5 className="text-base font-semibold text-gray-900">My Profile</h5>
                                </div>

                           

                            </div>
                            <p className="pt-3 pb-4 text-xs font-normal text-gray-500">
                               You can Manage your profile setting from here.
                            </p>
                            <Link to="/myprofile" className="py-1.5 pr-1.5 pl-3 rounded-md border border-gray-300 bg-gray-100 flex items-center justify-between text-xs font-medium text-gray-500 transition-all duration-300 hover:text-gray-900">
                                Edit Profile
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.00236 3.99707L10.0025 7.99723L6 11.9998" stroke="#6B7280" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </Link>
                        </div>

                        <div className="p-4 rounded-2xl border border-solid border-gray-200">
                            <div className="flex items-center justify-between gap-4">
                                <div className="flex items-center gap-3.5">
                                    <span className="rounded-2xl bg-gray-100 p-3">
                                  <img src="/images/svg/attendence.svg" alt="attendance" width={24} height={24} />
                                                             </span>
                                    <h5 className="text-base font-semibold text-gray-900">Mark Attendence</h5>
                                </div>

                               

                            </div>
                            <p className="pt-3 pb-4 text-xs font-normal text-gray-500">
                                Mark your daily attendance from here.
                            </p>
                            <Link to="/attendance" className="py-1.5 pr-1.5 pl-3 rounded-md border border-gray-300 bg-gray-100 flex items-center justify-between text-xs font-medium text-gray-500 transition-all duration-300 hover:text-gray-900">
                                Mark Attendence
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.00236 3.99707L10.0025 7.99723L6 11.9998" stroke="#6B7280" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </Link>
                        </div>

                        <div className="p-4 rounded-2xl border border-solid border-gray-200">
                            <div className="flex items-center justify-between gap-4">
                                <div className="flex items-center gap-3.5">
                                    <span className="rounded-2xl bg-gray-100 p-3">
                                    <img src="/images/svg/leave.svg" alt="attendance" width={24} height={24} />
                                    </span>
                                    <h5 className="text-base font-semibold text-gray-900">Leaves</h5>
                                </div>

                             

                            </div>
                            <p className="pt-3 pb-4 text-xs font-normal text-gray-500">
                               Mark your Leaves from here.
                            </p>
                            <Link to="/leaveform" className="py-1.5 pr-1.5 pl-3 rounded-md border border-gray-300 bg-gray-100 flex items-center justify-between text-xs font-medium text-gray-500 transition-all duration-300 hover:text-gray-900">
                                Learn More
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.00236 3.99707L10.0025 7.99723L6 11.9998" stroke="#6B7280" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </Link>
                        </div>

                        <div className="p-4 rounded-2xl border border-solid border-gray-200">
                            <div className="flex items-center justify-between gap-4">
                                <div className="flex items-center gap-3.5">
                                    <span className="rounded-2xl bg-gray-100 p-3">
                                    <img src="/images/svg/money-bag.svg" alt="attendance" width={24} height={24} />
                                    </span>
                                    <h5 className="text-base font-semibold text-gray-900">Expense Reimbursement</h5>
                                </div>

                             

                            </div>
                            <p className="pt-3 pb-4 text-xs font-normal text-gray-500">
                                Enter your daily expenses and get reimbursed.
                            </p>
                            <Link to="/expenseform" className="py-1.5 pr-1.5 pl-3 rounded-md border border-gray-300 bg-gray-100 flex items-center justify-between text-xs font-medium text-gray-500 transition-all duration-300 hover:text-gray-900">
                                Learn More
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.00236 3.99707L10.0025 7.99723L6 11.9998" stroke="#6B7280" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </Link>
                        </div>

                    </div> 
    </DashboardLayout>
  );
};

export default UserDashboard;