import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaSignOutAlt, FaArrowLeft, FaUserCircle, FaBell } from "react-icons/fa";
import { getAuth, signOut } from "firebase/auth";
import { useAuth } from "../../Pages/AuthContext";
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import { database } from "../../components/Firebase/firebase";

interface DashboardLayoutProps {
  title: string;
  children: React.ReactNode;
  showBackButton?: boolean;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  title,
  children,
  showBackButton = false,
}) => {
  const navigate = useNavigate();
  const { userName, user } = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [gender, setGender] = useState<string>("");
  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Logout failed:", error);
      alert("Failed to log out. Please try again.");
    }
  };

  const handleBack = () => {
    navigate(-1);
  };
  // Fetch user's gender
  useEffect(() => {
    const fetchUserGender = async () => {
      if (user) {
        try {
          const userDocRef = doc(database, "users", user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            setGender(userData.gender || ""); // Set gender from Firestore
          }
        } catch (error) {
          console.error("Error fetching user gender:", error);
        }
      }
    };

    fetchUserGender();
  }, [user]);
  return (
   
      <div className="dashboard-container  shadow-lg overflow-hidden w-full ">

        {/* Header */}
        <div className="dashboard-header px-3 sm:px-4 md:px-6 py-3 sm:py-4 md:py-5 flex justify-between items-center">
          <div className="container mx-auto px-0 flex justify-between items-center w-full">
            {/* Logo */}
            <div className="font-bold text-xl text-gray-800">
              <img src="/images/logo/cprlogo.png" alt="Logo" className="h-7 sm:h-8 inline-block" />
            </div>

            {/* Actions (Back Button, Notifications, Profile) */}
            <div className="flex items-center space-x-2 sm:space-x-3 md:space-x-4">
              {/* Back Button */}
              {showBackButton && (
                <button
                  onClick={handleBack}
                  className="flex items-center bg-red-500 text-white px-2 sm:px-3 py-1.5 sm:py-2 rounded-lg hover:bg-red-600 transition-colors text-sm sm:text-base"
                >
                  <FaArrowLeft className="mr-2" size={16} />
                  <span className="hidden sm:inline">Back</span>
                </button>
              )}

              {/* Notifications */}
              <button className="p-1.5 sm:p-2 rounded-full hover:bg-gray-100 transition">
                <FaBell className="text-gray-600 text-lg sm:text-xl" />
              </button>

              {/* Profile Dropdown */}
              <div className="relative">
                <button
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  className="flex items-center space-x-1 sm:space-x-2 bg-gray-100 text-gray-800 px-2 sm:px-3 py-1.5 sm:py-2 rounded-lg hover:bg-gray-200 transition-colors"
                >
                  <img
                    src={gender === "male"
                      ? "/images/avatar/avatarmale.webp"
                      : "/images/avatar/avatarfemale.webp"}
                    alt="Profile"
                    className="h-7 w-7 sm:h-8 sm:w-8 rounded-full object-cover"
                  />

                </button>

                {/* Dropdown Menu */}
                {dropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-md border border-gray-200">
                    <ul className="py-2">
                      <li>
                        <span className="font-medium block w-full text-left px-4 py-2 ">{userName || "User"}</span>
                      </li>
                      <li>
                        <button
                          onClick={() => navigate("/myprofile")}
                          className="block w-full text-left px-4 py-2 hover:bg-gray-100 transition-colors"
                        >
                          <FaUserCircle className="inline-block mr-2" />
                          Profile
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={handleLogout}
                          className="block w-full text-left px-4 py-2 hover:bg-gray-100 transition-colors"
                        >
                          <FaSignOutAlt className="inline-block mr-2" />
                          Logout
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Main Content */}

        <div className="container mx-auto px-3 sm:px-4 md:px-6 pt-3 sm:pt-4 md:pt-5">{children}</div>


      </div>

  );
};

export default DashboardLayout;