import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { useAuth } from "./Pages/AuthContext"; // Ensure this path is correct
import AdminLogin from "./Pages/AdminLogin";
import AdminDashboard from "./Pages/AdminDashboard";
import UserDashboard from "./Pages/UserDashboard";
import FinanceDashboard from "./Pages/FinanceDashboard";
import Signup from "./Pages/AdminSignup";
import ProtectedRoute from "./Pages/protectedroute"; // Import the ProtectedRoute components
import UsersManagement from "./components/AdminComp/usermanagement";
import MyProfile from "./components/MyProfile";
import AttendancePage from "./components/UserAttendence";
import UserAttendancePage from "./components/UserAttendence/adminview";
import LeavesPage from "./Pages/Leaveform";
import AMDashboard from "./Pages/amdashboard";
import LeavesList from "./Pages/LeaveList";
import LeaveSubmit from "./Pages/Leavesubmit";
import AllAttendancePage from "./components/UserAttendence/allattendence";
import ExpensesForm from "./Pages/Expenseform";
import ExpenseListPage from "./Pages/ExpenseList";
import MediaDashboard from "./Pages/MediaDashboard";
import MediaAttendancePage from "./components/UserAttendence/mediaattendence";
import AdminNotificationCreator from "./components/AdminNotification/AdminNotificationManager";
 


const App: React.FC = () => {
  const { user, role } = useAuth();

  return (
   
      <>


    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/adminsignup" element={<Signup />} />
        <Route path="/myprofile" element={<MyProfile />} />
      
       

       {/* Media team lgoins */}

       <Route
          path="/mediateamdashboard"
          element={
            <ProtectedRoute role="mediateam,admin">
              <MediaDashboard/>
            </ProtectedRoute>
          }
        /> 
        <Route
          path="/mediaattendance"
          element={
            <ProtectedRoute role="mediateam,admin">
              <MediaAttendancePage/>
            </ProtectedRoute>
          }
        /> 

        {/*Finance route  */}
        <Route
          path="/amdashboard"
          element={
            <ProtectedRoute role="am">
              <AMDashboard/>
            </ProtectedRoute>
          }
        />


       {/* Account Managere  Routes */}
       
        <Route
          path="/leavelist"
          element={
            <ProtectedRoute role="am,finance">
              <LeavesList/>
            </ProtectedRoute>
          }
        />
        <Route
          path="/leavesubmit"
          element={
            <ProtectedRoute role="am">
              <LeaveSubmit/>
            </ProtectedRoute>
          }
        />
         <Route
          path="/expenseform"
          element={
            <ProtectedRoute role="am,user,finance,admin">
              <ExpensesForm/>
            </ProtectedRoute>
          }
        />
        <Route
          path="/expenselistpage"
          element={
            <ProtectedRoute role="am,finance">
              <ExpenseListPage/>
            </ProtectedRoute>
          }
        />
        {/* End Account Managere  Routes */}

        {/* Redirect logged-in users away from login */}
        <Route
          path="/adminlogin"
          element={user ? <Navigate to={`/${role}dashboard`} replace /> : <AdminLogin />}
        />
         
        {/* Protected Routes */}
        
        <Route
          path="/nofificationmanageer"
          element={
            <ProtectedRoute role="admin">
              <AdminNotificationCreator />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admindashboard"
          element={
            <ProtectedRoute role="admin">
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
         <Route
          path="/allattendance"
          element={
            <ProtectedRoute role="am,finance,admin">
              <AllAttendancePage/>
            </ProtectedRoute>
          }
        />
         <Route
          path="/userattendance"
          element={
            <ProtectedRoute role="admin,">
              <UserAttendancePage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/usersmanagement"
          element={
            <ProtectedRoute role="admin,am">
              <UsersManagement />
            </ProtectedRoute>
          }
          />
        <Route
          path="/userdashboard"
          element={
            <ProtectedRoute role="user">
              <UserDashboard />
            </ProtectedRoute>
          }
        />
          <Route
          path="/leaveform"
          element={
            <ProtectedRoute role="user,am,finance">
              <LeavesPage/>
            </ProtectedRoute>
          }
        />

        <Route
          path="/attendance"
          element={
            <ProtectedRoute role="user,am,finance,admin">
              <AttendancePage />
            </ProtectedRoute>
          }
        />
 
        <Route
          path="/financedashboard"
          element={
            <ProtectedRoute role="finance">
              <FinanceDashboard />
            </ProtectedRoute>
          }
        />

        {/* Catch-all Route */}
        <Route
          path="*"
          element={
            user ? (
              <Navigate to={`/${role}dashboard`} replace />
            ) : (
              <Navigate to="/adminlogin" replace />
            )
          }
        />
      </Routes>
    </Router>
    </>
  );
};

export default App;
