import React, { useEffect, useState } from "react";
import { useAuth } from "./AuthContext";
import { FaUser, FaMoneyBill, FaCalendarAlt, FaCog, FaUserCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import DashboardLayout from "../layouts/dashboardlayout";
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import { database } from "../components/Firebase/firebase";
import { format } from "date-fns";
import AMDashboardLayout from "../layouts/dashboardlayout/amdashboard";

interface AttendanceRecord {
  date: string;
  checkInTime?: string;
  checkOutTime?: string;
  status: string;
}

const FinanceDashboard: React.FC = () => {
  const { userName, logout, user } = useAuth();
  const navigate = useNavigate();
  const [attendanceStatus, setAttendanceStatus] = useState<AttendanceRecord | null>(null);
  const [loading, setLoading] = useState(true);
  const [gender, setGender] = useState<string>("");

  // Redirect to login if user is not authenticated
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  // Fetch user's gender
  useEffect(() => {
    const fetchUserGender = async () => {
      if (user) {
        try {
          const userDocRef = doc(database, "users", user.uid);
          const userDoc = await getDoc(userDocRef);
          
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setGender(userData.gender || ""); // Set gender from Firestore
          }
        } catch (error) {
          console.error("Error fetching user gender:", error);
        }
      }
    };

    fetchUserGender();
  }, [user]);

  // Rest of your existing code...
  // (Keep all other useEffect hooks, functions, and the return statement)

  // Fetch today's attendance record
  useEffect(() => {
    const fetchTodaysAttendance = async () => {
      if (user) {
        const today = format(new Date(), "yyyy-MM-dd");
        const q = query(
          collection(database, "attendance"),
          where("userId", "==", user.uid),
          where("date", "==", today)
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const record = querySnapshot.docs[0].data() as AttendanceRecord;
          setAttendanceStatus(record);
        } else {
          setAttendanceStatus(null);
        }
        setLoading(false);
      }
    };

    fetchTodaysAttendance();
  }, [user]);
 
  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
    </div>
    )
}

  return (

    <AMDashboardLayout title="User Dashboard">
      <h1 className="dashboard-greeting text-2xl font-bold text-text-dark mb-2">
        <span className="block">Hey, <span className="font-light">{userName}!</span></span>  </h1>
        <div className="metrics-summary flex flex-col md:flex-row items-start justify-start gap-4 mb-5">
        {loading ? (
              <p className="text-gray-600 dark:text-gray-400">Loading attendance status...</p>
            ) : attendanceStatus ? (
              <div className="flex flex-col sm:flex-row items-center justify-center gap-2 mt-4">
                <span className="font-bold">Status:</span>
                <div className="metric-badge unusual bg-badge-unusual-bg text-badge-unusual-text px-4 py-2 rounded-full text-sm font-medium"><strong className="ml-1">{attendanceStatus.status}</strong></div>
                {attendanceStatus.checkInTime && (
                
                    <div className="metric-badge unusual bg-badge-unusual-bg text-badge-unusual-text px-4 py-2 rounded-full text-sm font-medium"><strong className="ml-1"> {attendanceStatus.checkInTime}</strong></div>
              
                )}
                {attendanceStatus.checkOutTime && (
                     <div className="metric-badge normal bg-badge-normal-bg text-badge-normal-text px-4 py-2 rounded-full text-sm font-medium">Late <strong className="ml-1">{attendanceStatus.checkOutTime}</strong></div>
                 
                )}
              </div>
            ) : (
              <p className="text-lg text-gray-600 dark:text-gray-400">Your attendance is pending.</p>
            )}
            </div>
      
            <div className="card-grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 pt-20 pb-20">
    <section className="dashboard-card bg-card-light-bg rounded-xl p-5 shadow-sm border border-card-border">
        <div className="card-header flex justify-between items-center mb-4">
            <h2 className="card-title text-lg font-semibold text-text-grey">My Profile</h2> 
            <div className="card-status unusual bg-status-unusual-bg text-status-unusual-text px-2 py-1 rounded-full text-xs font-medium">Online</div>
        </div>
        
        <div className="card-actions flex flex-col gap-2">
        <img src={gender === "male" 
                ? "/images/avatar/avatarmale.webp" 
                : "/images/avatar/avatarfemale.webp"} alt="avatar" className="w-40 h-40 rounded-full mx-auto mb-4" />
        
            <Link to="/myprofile" className="card-action-btn bg-btn-light-bg text-text-grey border-none px-4 py-2 rounded-lg cursor-pointer font-medium text-left flex justify-between items-center hover:bg-btn-hover-bg">
                Edit profile
                <svg className="action-icon opacity-70" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <rect x="4" y="4" width="16" height="16" rx="2" ry="2"></rect>
                    <rect x="9" y="9" width="6" height="6"></rect>
                    <line x1="2" y1="9" x2="4" y2="9"></line>
                    <line x1="2" y1="15" x2="4" y2="15"></line>
                    <line x1="20" y1="9" x2="22" y2="9"></line>
                    <line x1="20" y1="15" x2="22" y2="15"></line>
                    <line x1="9" y1="2" x2="9" y2="4"></line>
                    <line x1="15" y1="2" x2="15" y2="4"></line>
                    <line x1="9" y1="20" x2="9" y2="22"></line>
                    <line x1="15" y1="20" x2="15" y2="22"></line>
                </svg>
            </Link>
            <Link to="/usersmanagement" className="card-action-btn bg-btn-light-bg text-text-grey border-none px-4 py-2 rounded-lg cursor-pointer font-medium text-left flex justify-between items-center hover:bg-btn-hover-bg">
  View / Create Team Member
  <svg className="action-icon opacity-70" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
    <circle cx="8" cy="7" r="3"/>
    <path d="M11 13H5a2 2 0 0 0-2 2v4"/>
    <circle cx="16" cy="7" r="3"/>
    <path d="M21 13h-6a2 2 0 0 0-2 2v4"/>
  </svg>
</Link>
        
        </div> 
    </section>

    {/* attendence code below  */}
    <section className="dashboard-card bg-card-light-bg rounded-xl p-5 shadow-sm border border-card-border">
    <div className="card-header flex justify-between items-center mb-4">
        <h2 className="card-title text-lg font-semibold text-text-grey">Attendance</h2>
        <div className="card-status bg-status-normal-bg text-status-normal-text px-2 py-1 rounded-full text-xs font-medium">{attendanceStatus ? attendanceStatus.status : "No status"}</div>
    </div>
    <div className="card-value text-1xl font-bold text-text-dark mb-2">
   
    </div>

    <img src="/images/home/6553542.webp" alt="avatar" className="w-40 h-40  mx-auto mb-4" />
    <div className="card-actions flex flex-col gap-2">
        <Link to="/attendance" className="card-action-btn bg-btn-light-bg text-text-grey border-none px-4 py-2 rounded-lg cursor-pointer font-medium text-left flex justify-between items-center hover:bg-btn-hover-bg">
          Mark Attendence
            <svg className="action-icon opacity-70" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <rect x="4" y="4" width="16" height="16" rx="2" ry="2"></rect>
                <rect x="9" y="9" width="6" height="6"></rect>
                <line x1="2" y1="9" x2="4" y2="9"></line>
                <line x1="2" y1="15" x2="4" y2="15"></line>
                <line x1="20" y1="9" x2="22" y2="9"></line>
                <line x1="20" y1="15" x2="22" y2="15"></line>
                <line x1="9" y1="2" x2="9" y2="4"></line>
                <line x1="15" y1="2" x2="15" y2="4"></line>
                <line x1="9" y1="20" x2="9" y2="22"></line>
                <line x1="15" y1="20" x2="15" y2="22"></line>
            </svg>
        </Link>
        <Link to="/allattendance" className="card-action-btn bg-btn-light-bg text-text-grey border-none px-4 py-2 rounded-lg cursor-pointer font-medium text-left flex justify-between items-center hover:bg-btn-hover-bg">
          Team Attendence
          <svg className="action-icon opacity-70" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline><polyline points="17 6 23 6 23 12"></polyline></svg>
        </Link>
    </div>
</section>

 {/* Leeave form code below  */}
 <section className="dashboard-card bg-card-light-bg rounded-xl p-5 shadow-sm border border-card-border">
    <div className="card-header flex justify-between items-center mb-4">
        <h2 className="card-title text-lg font-semibold text-text-grey">Leaves</h2>
        <div className="card-status bg-status-normal-bg text-status-normal-text px-2 py-1 rounded-full text-xs font-medium"> Nill</div>
    </div>
    <div className="card-value text-1xl font-bold text-text-dark mb-2">
   
    </div>

    <img src="/images/home/leave.webp" alt="avatar" className="w-40 h-40  mx-auto mb-4" />
    <div className="card-actions flex flex-col gap-2">
        <Link to="/leaveform" className="card-action-btn bg-btn-light-bg text-text-grey border-none px-4 py-2 rounded-lg cursor-pointer font-medium text-left flex justify-between items-center hover:bg-btn-hover-bg">
          Submit Leave Application
            <svg className="action-icon opacity-70" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <rect x="4" y="4" width="16" height="16" rx="2" ry="2"></rect>
                <rect x="9" y="9" width="6" height="6"></rect>
                <line x1="2" y1="9" x2="4" y2="9"></line>
                <line x1="2" y1="15" x2="4" y2="15"></line>
                <line x1="20" y1="9" x2="22" y2="9"></line>
                <line x1="20" y1="15" x2="22" y2="15"></line>
                <line x1="9" y1="2" x2="9" y2="4"></line>
                <line x1="15" y1="2" x2="15" y2="4"></line>
                <line x1="9" y1="20" x2="9" y2="22"></line>
                <line x1="15" y1="20" x2="15" y2="22"></line>
            </svg>
        </Link>
        <Link to="/leavelist" className="card-action-btn bg-btn-light-bg text-text-grey border-none px-4 py-2 rounded-lg cursor-pointer font-medium text-left flex justify-between items-center hover:bg-btn-hover-bg">
          Team Leave Applications
          <svg className="action-icon opacity-70" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline><polyline points="17 6 23 6 23 12"></polyline></svg>
        </Link>
       
    </div>
</section>

 {/* Expense Reimbursment form code below  */}
 <section className="dashboard-card bg-card-light-bg rounded-xl p-5 shadow-sm border border-card-border">
    <div className="card-header flex justify-between items-center mb-4">
        <h2 className="card-title text-lg font-semibold text-text-grey">Expenses Reimbursement</h2>
        <div className="card-status bg-status-normal-bg text-status-normal-text px-2 py-1 rounded-full text-xs font-medium"> Nill</div>
    </div>
    <div className="card-value text-1xl font-bold text-text-dark mb-2">
   
    </div>

    <img src="/images/home/6289247.webp" alt="avatar" className="w-40 h-40  mx-auto mb-4" />
    <div className="card-actions flex flex-col gap-2">
        <Link to="/expenseform" className="card-action-btn bg-btn-light-bg text-text-grey border-none px-4 py-2 rounded-lg cursor-pointer font-medium text-left flex justify-between items-center hover:bg-btn-hover-bg">
         New Application
            <svg className="action-icon opacity-70" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <rect x="4" y="4" width="16" height="16" rx="2" ry="2"></rect>
                <rect x="9" y="9" width="6" height="6"></rect>
                <line x1="2" y1="9" x2="4" y2="9"></line>
                <line x1="2" y1="15" x2="4" y2="15"></line>
                <line x1="20" y1="9" x2="22" y2="9"></line>
                <line x1="20" y1="15" x2="22" y2="15"></line>
                <line x1="9" y1="2" x2="9" y2="4"></line>
                <line x1="15" y1="2" x2="15" y2="4"></line>
                <line x1="9" y1="20" x2="9" y2="22"></line>
                <line x1="15" y1="20" x2="15" y2="22"></line>
            </svg>
        </Link>
        <Link to="/expenselistpage" className="card-action-btn bg-btn-light-bg text-text-grey border-none px-4 py-2 rounded-lg cursor-pointer font-medium text-left flex justify-between items-center hover:bg-btn-hover-bg">
           View Record
          <svg className="action-icon opacity-70" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline><polyline points="17 6 23 6 23 12"></polyline></svg>
        </Link>
       
    </div>
</section>

</div>
 
    </AMDashboardLayout>
  );
};

export default FinanceDashboard;
