// src/layouts/dashboardlayout/amdashboard/notification.tsx
import React from 'react';

interface NotificationBadgeProps {
  count: number;
}

const NotificationBadge: React.FC<NotificationBadgeProps> = ({ count }) => {
  if (count <= 0) {
    return null;
  }

  return (
    <span
      className="notification-badge"
      style={{
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '-8px',
        right: '-8px',
        padding: '2px 6px',
        fontSize: '0.7rem',
        fontWeight: 'bold',
        backgroundColor: 'red',
        color: 'white',
        borderRadius: '50%',
      }}
    >
      {count}
    </span>
  );
};

export default NotificationBadge;