import React, { useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import DashboardLayout from "../layouts/dashboardlayout";
import {
  collection,
  query,
  getDocs,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { database } from "../components/Firebase/firebase";
import { format, parseISO } from "date-fns";
import { calculateWorkingDays } from "../utils/utils";

interface LeaveRecord {
  id?: string;
  userId: string;
  startDate: string;
  endDate: string;
  leaveType: string;
  reason: string;
  status: "pending" | "approved" | "rejected" | "unpaid" | "extra";
  createdAt: string;
  leaveDays: number;
}

interface UserDetails {
  name: string;
  email: string;
  leaveBalances?: {
    sick: number;
    annual: number;
    casual: number;
    unpaid: number;
    extra: number;
  };
}

const LeaveListPage: React.FC = () => {
  const { user } = useAuth();
  const [leaveHistory, setLeaveHistory] = useState<LeaveRecord[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLeaveHistory = async () => {
      if (user) {
        const q = query(collection(database, "leaves"));
        const querySnapshot = await getDocs(q);
        const leaves = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as LeaveRecord[];

        setLeaveHistory(leaves);
      }
      setLoading(false);
    };

    fetchLeaveHistory();
  }, [user]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <DashboardLayout title="Leave List" showBackButton>
      <div className="min-h-screen   p-6 pt-20">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">
          Team Leave Applications
        </h1>

        {/* New Leave Notifications */}
        {leaveHistory
          .filter((leave) => leave.status === "pending")
          .map((leave, index) => (
            <LeaveNotification
              key={index}
              leave={leave}
              setLeaveHistory={setLeaveHistory}
              leaveHistory={leaveHistory}
            />
          ))}

        {/* Leave History */}
        <div className="bg-white shadow-md rounded-2xl p-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">
            Leave History
          </h2>
          <table className="min-w-full border border-gray-200 rounded-lg">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-3 px-4 text-left text-sm text-gray-600 font-semibold">
                  User
                </th>
                <th className="py-3 px-4 text-left text-sm text-gray-600 font-semibold">
                  Start Date
                </th>
                <th className="py-3 px-4 text-left text-sm text-gray-600 font-semibold">
                  End Date
                </th>
                <th className="py-3 px-4 text-left text-sm text-gray-600 font-semibold">
                  Leave Type
                </th>
                <th className="py-3 px-4 text-left text-sm text-gray-600 font-semibold">
                  Reason
                </th>
                <th className="py-3 px-4 text-left text-sm text-gray-600 font-semibold">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {leaveHistory.map((leave, index) => (
                <LeaveRow key={index} leave={leave} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </DashboardLayout>
  );
};

interface LeaveNotificationProps {
  leave: LeaveRecord;
  leaveHistory: LeaveRecord[];
  setLeaveHistory: React.Dispatch<React.SetStateAction<LeaveRecord[]>>;
}

const LeaveNotification: React.FC<LeaveNotificationProps> = ({
  leave,
  setLeaveHistory,
  leaveHistory,
}) => {
  const { user } = useAuth();
  const [userDetails, setUserDetails] = useState<UserDetails>({
    name: "",
    email: "",
  });
  const [selectedLeaveType, setSelectedLeaveType] = useState<string>(leave.leaveType);
  const [isProcessing, setIsProcessing] = useState(false);
  const [processingLeaveId, setProcessingLeaveId] = useState<string | null>(null);


  useEffect(() => {
    const fetchUserDetails = async () => {
      if (leave.userId) {
        const userDoc = await getDoc(doc(database, "users", leave.userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserDetails({
            name: userData.name,
            email: userData.email,
            leaveBalances: userData.leaveBalances,
          });
        }
      }
    };

    fetchUserDetails();
  }, [leave.userId]);

  const handleStatusChange = async (newStatus: "pending" | "approved" | "rejected" | "unpaid" | "extra") => {
    if (user && leave.id && !isProcessing) {
      setIsProcessing(true);
      setProcessingLeaveId(leave.id);
      const leaveRef = doc(database, "leaves", leave.id);
      try {
        await updateDoc(leaveRef, { status: newStatus, leaveType: selectedLeaveType });
        const updatedLeaveHistory = leaveHistory.map((l) =>
          l.id === leave.id ? { ...l, status: newStatus, leaveType: selectedLeaveType } : l
        );
        setLeaveHistory(updatedLeaveHistory);

        const userRef = doc(database, "users", leave.userId);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          let userData = userDoc.data();
          let updatedBalances = { ...userData.leaveBalances };
          const leaveDays = leave.leaveDays;

          if (newStatus === "approved") {
              if (selectedLeaveType === "extra" || selectedLeaveType === "unpaid") {
                  if (updatedBalances && updatedBalances[selectedLeaveType]) {
                      updatedBalances[selectedLeaveType] += leaveDays;
                  } else if (updatedBalances) {
                      updatedBalances[selectedLeaveType] = leaveDays;
                  }
              } else {
                  if (updatedBalances && updatedBalances[selectedLeaveType]) {
                      updatedBalances[selectedLeaveType] -= leaveDays;
                      if (updatedBalances[selectedLeaveType] <= 1) {
                          alert(`Warning: User has only ${updatedBalances[selectedLeaveType]} ${selectedLeaveType} leaves remaining after approval.`);
                      }
                  }
              }
              await updateDoc(userRef, { leaveBalances: updatedBalances });
              setUserDetails(prevState => ({
                  ...prevState,
                  leaveBalances: updatedBalances
              }));
          }
        }
      } catch (error) {
        console.error("Error updating leave status:", error);
      } finally {
        setIsProcessing(false);
        setProcessingLeaveId(null);
      }
    }
  };

  const handleLeaveTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedLeaveType(e.target.value);
  };


  return (
    <div className="bg-white shadow-md rounded-2xl p-6 mb-4 border border-yellow-300">
      <h3 className="text-lg font-semibold text-gray-700 mb-2">
        New Leave Request
      </h3>
      <p className="text-gray-600 mb-2">
        <strong>User:</strong> {userDetails?.name} ({userDetails?.email})
      </p>
      <p className="text-gray-600 mb-2">
        <strong>Dates:</strong> {format(parseISO(leave.startDate), 'yyyy-MM-dd')} -{" "}
        {format(parseISO(leave.endDate), 'yyyy-MM-dd')}
      </p>
      <p className="text-gray-600 mb-2">
        <strong>Reason:</strong> {leave.reason}
      </p>
      <div className="mt-2 flex items-center justify-between">
        <div>
          <label className="block text-gray-600 font-semibold mb-2 text-sm">Leave Type:</label>
          <select
            value={selectedLeaveType}
            onChange={handleLeaveTypeChange}
            className="p-2 border rounded-md text-sm"
          >
            <option value="casual">Casual</option>
            <option value="annual">Annual</option>
            <option value="sick">Sick</option>
            <option value="unpaid">Unpaid</option>
            <option value="extra">Extra</option>
          </select>
        </div>
        <div className='mt-6'>
            <button
                onClick={() => handleStatusChange("approved")}
                className={`bg-green-500 text-white px-2 py-1 rounded text-xs hover:bg-green-700 mr-1 ${isProcessing && processingLeaveId === leave.id ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={isProcessing && processingLeaveId === leave.id}
            >
                {isProcessing && processingLeaveId === leave.id ? (
                    <span className="inline-flex items-center">
                        <svg className="animate-spin h-4 w-4 mr-1" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83"></path></svg>
                        Approving...
                    </span>
                ) : 'Approve'}
            </button>
            <button
                onClick={() => handleStatusChange("rejected")}
                className={`bg-red-500 text-white px-2 py-1 rounded text-xs hover:bg-red-700 ${isProcessing && processingLeaveId === leave.id ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={isProcessing && processingLeaveId === leave.id}
            >
                {isProcessing && processingLeaveId === leave.id ? (
                    <span className="inline-flex items-center">
                        <svg className="animate-spin h-4 w-4 mr-1" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83"></path></svg>
                        Rejecting...
                    </span>
                ) : 'Reject'}
            </button>
        </div>
      </div>
    </div>
  );
};

interface LeaveRowProps {
  leave: LeaveRecord;
}

const LeaveRow: React.FC<LeaveRowProps> = ({ leave }) => {
  const [userDetails, setUserDetails] = useState<UserDetails>({
    name: "",
    email: "",
  });
  useEffect(() => {
    const fetchUserDetails = async () => {
      if (leave.userId) {
        const userDoc = await getDoc(doc(database, "users", leave.userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserDetails({ name: userData.name, email: userData.email });
        }
      }
    };

    fetchUserDetails();
  }, [leave.userId]);
  return (
    <tr
      className={`hover:bg-gray-50 ${
        leave.status === "pending"
          ? "bg-yellow-50"
          : leave.status === "approved"
          ? "bg-green-50"
          : leave.status === "rejected"
          ? "bg-red-50"
          : leave.status === "unpaid"
          ? "bg-blue-50"
          : leave.status === "extra"
          ? "bg-purple-50"
          : "bg-white"
      }`}
    >
      <td className="py-3 px-4 text-sm text-gray-700">{userDetails?.name}</td>
      <td className="py-3 px-4 text-sm text-gray-700">{leave.startDate}</td>
      <td className="py-3 px-4 text-sm text-gray-700">{leave.endDate}</td>
      <td className="py-3 px-4 text-sm text-gray-700">{leave.leaveType}</td>
      <td className="py-3 px-4 text-sm text-gray-700">{leave.reason}</td>
      <td className="py-3 px-4 text-sm text-gray-700">
        <span
          className={`px-2 py-1 rounded-full text-sm font-semibold ${
            leave.status === "approved"
              ? "bg-green-100 text-green-700"
              : leave.status === "rejected"
              ? "bg-red-100 text-red-700"
              : leave.status === "unpaid"
              ? "bg-blue-100 text-blue-700"
              : leave.status === "extra"
              ? "bg-purple-100 text-purple-700"
              : "bg-yellow-100 text-yellow-700"
          }`}
        >
          {leave.status}
        </span>
      </td>
    </tr>
  );
};

export default LeaveListPage;