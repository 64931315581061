import React, { useState, useEffect } from "react";
import { database } from "../../components/Firebase/firebase";
import { collection, query, orderBy, onSnapshot, deleteDoc, doc } from "firebase/firestore";
import { formatDistanceToNow } from 'date-fns';
import { FaBell, FaTimes } from 'react-icons/fa'; 


interface AdminNotification {
  id: string;
  title: string;
  message: string;
  createdAt: { toDate: () => Date } | null;
}

const AdminNotificationBannerDisplay: React.FC = () => {
  const [notifications, setNotifications] = useState<AdminNotification[]>([]);
  const [loading, setLoading] = useState(true);
  const [displayedNotifications, setDisplayedNotifications] = useState<string[]>([]); // Track displayed notification IDs

  useEffect(() => {
    const q = query(
      collection(database, "adminNotifications"),
      orderBy("createdAt", "desc") // Newest first
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const notificationList: AdminNotification[] = [];
      snapshot.forEach((doc) => {
        notificationList.push({
          id: doc.id,
          ...(doc.data() as Omit<AdminNotification, 'id'>),
          createdAt: doc.data().createdAt || null,
        });
      });
      // Initially display the latest 3 notifications (adjust as needed)
      setNotifications(notificationList.slice(0, 3));
      setLoading(false);
    }, (error) => {
      console.error("Error fetching admin notifications:", error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleCloseNotification = (notificationId: string) => {
    setDisplayedNotifications([...displayedNotifications, notificationId]);
  };

  const visibleNotifications = notifications.filter(notification => !displayedNotifications.includes(notification.id));

  if (loading) {
    return null;
  }

  if (visibleNotifications.length === 0) {
    return null;
  }

  return (
  
    <div className="p-4 flex flex-col space-y-2 items-center mt-4 w-full"> {/* Removed fixed positioning and added mt-4 for top margin if needed */}
      {visibleNotifications.map((notification) => (
        <div key={notification.id}
          id={`toast-notification-${notification.id}`}
          className="flex items-center w-full max-w-md p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800"
          role="alert"
        >
          <div
            className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-blue-500 bg-blue-100 rounded-lg dark:bg-blue-800 dark:text-blue-200"
          >
             <FaBell className="w-4 h-4" />
            <span className="sr-only">Notification icon</span>
          </div>
          <div className="ml-3 text-sm font-normal">
            <span className="font-semibold mr-1">{notification.title}:</span>{notification.createdAt && (
              <span className="text-gray-500 ml-2">
                ({formatDistanceToNow(notification.createdAt.toDate(), { addSuffix: true })})
              </span>
            )}<br/>
             {notification.message}
            
          </div>
          <button
            type="button"
            className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
            data-dismiss-target={`#toast-notification-${notification.id}`}
            aria-label="Close"
            onClick={() => handleCloseNotification(notification.id)}
          >
            <span className="sr-only">Close</span>
            <FaTimes className="w-3 h-3" aria-hidden="true" />
          </button>
        </div>
      ))}
    </div>
  );
};

export default AdminNotificationBannerDisplay;