import React, { useState, useEffect } from "react";
import { useAuth } from "../../Pages/AuthContext";
import { collection, query, where, onSnapshot, doc, setDoc, updateDoc, getDocs } from "firebase/firestore";
import { database } from "../Firebase/firebase";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isAfter, parse, startOfDay, isSameMonth } from "date-fns";
import DashboardLayout from "../../layouts/dashboardlayout";
import "react-loading-skeleton/dist/skeleton.css";

const AllAttendancePage = () => {
  const { user, userName, role } = useAuth();
  const [attendance, setAttendance] = useState<{ userId: string; date: string; checkInTime?: string; checkOutTime?: string; status: string; workMode: string; latitude?: number; longitude?: number }[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allUsers, setAllUsers] = useState<any[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date()); 
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [currentMonthDates, setCurrentMonthDates] = useState<Date[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDateCalendar, setShowDateCalendar] = useState(false);
  const [selectedUserFilter, setSelectedUserFilter] = useState<string | null>(null);
  const [monthlySummary, setMonthlySummary] = useState<{ present: number; absent: number; late: number }>({ present: 0, absent: 0, late: 0 });
  const [dailySummary, setDailySummary] = useState<{ present: number; absent: number; late: number }>({ present: 0, absent: 0, late: 0 });
  const [attendanceStatusFilter, setAttendanceStatusFilter] = useState<string | null>(null); 

  useEffect(() => {
    const fetchAllUsers = async () => {
      setIsLoading(true);
      try {
        const usersQuery = query(collection(database, "users"));
        const unsubscribe = onSnapshot(usersQuery, (querySnapshot) => {
          const usersData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setAllUsers(usersData);
          setIsLoading(false);
        });
        return unsubscribe;
      } catch (error) {
        console.error("Error fetching users:", error);
        setIsLoading(false);
      }
    };
    fetchAllUsers();
  }, []);

  useEffect(() => {
    const fetchRealTimeAttendance = async () => {
      setIsLoading(true);
      try {
        const monthStartDate = startOfMonth(selectedDate);
        const monthEndDate = endOfMonth(selectedDate);
        setSelectedMonth(monthStartDate); // Update selectedMonth based on selectedDate for monthly summaries and calculations

        console.log("Fetching attendance for month derived from date:", format(monthStartDate, "MMMM yyyy")); // MONTH FETCHING LOG

        let q = query(
          collection(database, "attendance"),
          where("date", ">=", format(monthStartDate, "yyyy-MM-dd")),
          where("date", "<=", format(monthEndDate, "yyyy-MM-dd"))
        );

        if (selectedUserFilter) {
          q = query(q, where("userId", "==", selectedUserFilter));
        }

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const attendanceData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            userId: doc.data().userId,
            date: doc.data().date,
            checkInTime: doc.data().checkInTime || null,
            checkOutTime: doc.data().checkOutTime || null,
            status: doc.data().status,
            workMode: doc.data().workMode || "N/A",
            latitude: doc.data().latitude || null,
            longitude: doc.data().longitude || null,
          }));
          console.log("Raw attendance data fetched:", attendanceData); // RAW DATA LOG
          setAttendance(attendanceData);
          setIsLoading(false);
        });
        return unsubscribe;
      } catch (error) {
        console.error("Error fetching attendance:", error);
        setIsLoading(false);
      }
    };
    fetchRealTimeAttendance();
  }, [selectedDate, selectedUserFilter]); // Now depends on selectedDate

  useEffect(() => {
    const startDate = startOfMonth(selectedDate);
    const endDate = endOfMonth(selectedDate);
    const dates = eachDayOfInterval({ start: startDate, end: endDate });
    setCurrentMonthDates(dates);
    console.log("Current month dates updated based on selectedDate:", dates.map(d => format(d, "yyyy-MM-dd"))); // CURRENT MONTH DATES LOG
  }, [selectedDate]); // Now depends on selectedDate

  useEffect(() => {
    calculateMonthlySummary();
    calculateDailySummary();
  }, [attendance, selectedMonth, selectedDate, selectedUserFilter]);

  const calculateMonthlySummary = () => {
    let presentCount = 0;
    let absentCount = 0;
    let lateCount = 0;

    const startOfMonthDate = startOfMonth(selectedMonth); // Still use selectedMonth for monthly summary calculations
    const endOfMonthDate = endOfMonth(selectedMonth);

    const filteredAttendance = attendance.filter(record => {
      const recordDate = parse(record.date, "yyyy-MM-dd", new Date());
      return isSameMonth(recordDate, selectedMonth);
    });

    if (selectedUserFilter) {
      const userMonthlyAttendance = filteredAttendance.filter(record => record.userId === selectedUserFilter);
      currentMonthDates.forEach(date => {
        const formattedDate = format(date, "yyyy-MM-dd");
        const record = userMonthlyAttendance.find(rec => rec.date === formattedDate);
        if (record) {
          if (record.status === 'present') {
            presentCount++;
          } else if (record.status === 'late') {
            lateCount++;
          } else if (record.status === 'absent') {
            absentCount++;
          }
        } else {
          absentCount++;
        }
      });
    } else {
      filteredAttendance.forEach(record => {
        if (record.status === 'present') {
          presentCount++;
        } else if (record.status === 'late') {
          lateCount++;
        } else if (record.status === 'absent') {
          absentCount++;
        }
      });
    }

    setMonthlySummary({ present: presentCount, absent: absentCount, late: lateCount });
  };

  const calculateDailySummary = () => {
    let presentCount = 0;
    let absentCount = 0;
    let lateCount = 0;

    const selectedDateString = format(selectedDate, "yyyy-MM-dd");
    const dailyAttendance = attendance.filter(record => record.date === selectedDateString);

    if (!selectedUserFilter) {
      allUsers.forEach(user => {
        const record = dailyAttendance.find(rec => rec.userId === user.id);
        if (record) {
          if (record.status === 'present') {
            presentCount++;
          } else if (record.status === 'late') {
            lateCount++;
          } else if (record.status === 'absent') {
            absentCount++;
          }
        } else {
          absentCount++;
        }
      });
    } else {
      const userDailyAttendance = dailyAttendance.filter(record => record.userId === selectedUserFilter);
      const record = userDailyAttendance[0];
      if (record) {
        if (record.status === 'present') {
          presentCount = 1;
        } else if (record.status === 'late') {
          lateCount++;
        } else if (record.status === 'absent') {
          absentCount = 1;
        }
      } else {
        absentCount = 1;
      }
    }

    setDailySummary({ present: presentCount, absent: absentCount, late: lateCount });
  };

  const handleMarkAttendance = async (userId: string, workMode: string, action: "checkIn" | "checkOut") => {
    const today = format(new Date(), "yyyy-MM-dd");
    const time = format(new Date(), "hh:mm:ss a");
    const attendanceRef = doc(database, "attendance", `${userId}_${today}`);

    try {
      let latitude: number | null = null;
      let longitude: number | null = null;

      if (workMode === "onSite") {
        const position = await new Promise<GeolocationPosition>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;
      }

      const isLate = isAfter(new Date(), parse("11:15 AM", "hh:mm a", new Date()));
      const status = isLate ? "late" : "present";

      if (action === "checkIn") {
        await setDoc(attendanceRef, {
          userId,
          date: today,
          checkInTime: time,
          status,
          workMode,
          latitude: workMode === "onSite" ? latitude : null,
          longitude: workMode === "onSite" ? longitude : null,
        });
      } else if (action === "checkOut") {
        await updateDoc(attendanceRef, {
          checkOutTime: time,
        });
      }
    } catch (error) {
      console.error("Error marking attendance:", error);
    }
  };

  const getAttendanceForSelectedDate = (userId: string, date: Date) => {
    const selectedDateString = format(date, "yyyy-MM-dd");
    return attendance.find((record) => record.userId === userId && record.date === selectedDateString) || null;
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "present": return "text-green-500";
      case "late": return "text-yellow-500";
      case "absent": return "text-red-500";
      default: return "text-gray-500";
    }
  };

  const handleUserFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedUserFilter(e.target.value === "" ? null : e.target.value);
  };

  const handleAttendanceStatusFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAttendanceStatusFilter(e.target.value === "" ? null : e.target.value);
  };


  return (
    <DashboardLayout title="User Attendance" showBackButton>
      <div className="min-h-screen p-6">
        <h1 className="text-2xl font-bold mb-6">Team Attendance</h1>

        <div className="mb-4 flex items-center gap-4">
          {/* User Filter Dropdown */}
          <div className="flex-1">
            <label htmlFor="userFilter" className="block text-sm font-bold text-gray-700 mb-1">Filter by User</label>
            <select
              id="userFilter"
              className="mt-1 block w-full rounded-md border-gray-300  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-2 shadow-lg shadow-black-100"
              onChange={handleUserFilterChange}
              value={selectedUserFilter || ""}  >
              <option value="">All Users (Today's Summary)</option>
              {allUsers.map((user) => (
                <option key={user.id} value={user.id}>{user.name} (Monthly View)</option>
              ))}
            </select>
          </div>

          {/* Attendance Status Filter Dropdown */}
          <div className="flex-1">
            <label htmlFor="attendanceStatusFilter" className="block text-sm font-bold text-gray-700 mb-1">Filter by Status</label>
            <select
              id="attendanceStatusFilter"
              className="mt-1 block w-full rounded-md border-gray-300  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-2 shadow-lg shadow-black-100"
              onChange={handleAttendanceStatusFilterChange}
              value={attendanceStatusFilter || ""}
            >
              <option value="">All Statuses</option>
              <option value="present">Present</option>
              <option value="late">Late</option>
              <option value="absent">Absent</option>
            </select>
          </div>


          {/* Date Picker (Now includes Month Selection) */}
          <div className="flex-1 relative">
            <label className="block text-sm font-bold text-gray-700 mb-1">
              Select Date
            </label>
            <div
              className="border rounded-lg p-2 cursor-pointer bg-white"
              onClick={() => setShowDateCalendar(true)}
            >
              {format(selectedDate, "yyyy-MM-dd")}
            </div>

            {showDateCalendar && (
              <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center"
                onClick={() => setShowDateCalendar(false)}
              >
                <div className="bg-white rounded-lg p-4" onClick={e => e.stopPropagation()}>
                  <Calendar
                    onChange={(value) => {
                      const date = value as Date;
                      setSelectedDate(startOfDay(date));
                      setShowDateCalendar(false);
                    }}
                    value={selectedDate}
                  />
                </div>
              </div>
            )}
          </div>
        </div>


        {/* Monthly Summary for Selected User */}
        {selectedUserFilter && (
          <div className="bg-white p-6 rounded-lg shadow-md mb-6">
            <h3 className="text-xl font-semibold mb-4">Monthly Summary for {allUsers.find(u => u.id === selectedUserFilter)?.name} - {format(selectedMonth, "MMMM yyyy")}</h3>
            <div className="flex gap-4">
              <div>Total Presents: <span className="font-bold">{monthlySummary.present}</span></div>
              <div>Total Absents: <span className="font-bold">{monthlySummary.absent}</span></div>
              <div>Total Lates: <span className="font-bold">{monthlySummary.late}</span></div>
            </div>
          </div>
        )}

        {/* Daily Summary for All Users */}
        {!selectedUserFilter && (
          <div className="bg-white p-6 rounded-lg shadow-md mb-6">
            <h3 className="text-xl font-semibold mb-4">Today's Attendance Summary - {format(selectedDate, "yyyy-MM-dd")}</h3>
            <div className="flex gap-4">
              <div>Total Presents: <span className="font-bold">{dailySummary.present}</span></div>
              <div>Total Absents: <span className="font-bold">{dailySummary.absent}</span></div>
              <div>Total Lates: <span className="font-bold">{dailySummary.late}</span></div>
            </div>
          </div>
        )}


        {/* Attendance Table */}
        <div className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h2 className="text-xl font-semibold mb-4">Attendance Details</h2>
          {isLoading ? (
            <div className="text-center">Loading...</div>
          ) : (
            <table className="w-full table-auto border-collapse border border-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-2 border">Name</th>
                  <th className="px-4 py-2 border">Role</th>
                  <th className="px-4 py-2 border">Date</th>
                  <th className="px-4 py-2 border">Attendance</th>
                  <th className="px-4 py-2 border">Check-in Time</th>
                  <th className="px-4 py-2 border">Check-out Time</th>
                  <th className="px-4 py-2 border">Work Mode</th>
                </tr>
              </thead>
              <tbody>
                {selectedUserFilter ?
                  currentMonthDates.map(date => {
                    const user = allUsers.find(u => u.id === selectedUserFilter);
                    if (!user) return null;
                    const attendanceRecord = getAttendanceForSelectedDate(user.id, date);
                    const status = attendanceRecord?.status || "absent";
                    if (attendanceStatusFilter && attendanceStatusFilter !== "" && status !== attendanceStatusFilter) {
                      return null;
                    }
                    return (
                      <tr key={`${user.id}-${format(date, "yyyy-MM-dd")}`}>
                        <td className="px-4 py-2 border">{user.name}</td>
                        <td className="px-4 py-2 border">{user.role}</td>
                        <td className="px-4 py-2 border">{format(date, "yyyy-MM-dd")}</td>
                        <td className={`px-4 py-2 border ${getStatusColor(status)}`}>
                          {status.charAt(0).toUpperCase() + status.slice(1)}
                        </td>
                        <td className="px-4 py-2 border">{attendanceRecord?.checkInTime || "N/A"}</td>
                        <td className="px-4 py-2 border">{attendanceRecord?.checkOutTime || "N/A"}</td>
                        <td className="px-4 py-2 border">
                          {attendanceRecord?.workMode === "onSite"
                            ? `On Site (${attendanceRecord.latitude?.toFixed(4)}, ${attendanceRecord.longitude?.toFixed(4)})`
                            : attendanceRecord?.workMode || "N/A"}
                        </td>
                      </tr>
                    );
                  })
                  :
                  allUsers.map((user) => {
                    const attendanceRecord = getAttendanceForSelectedDate(user.id, selectedDate);
                    const status = attendanceRecord?.status || "absent";
                    if (attendanceStatusFilter && attendanceStatusFilter !== "" && status !== attendanceStatusFilter) {
                      return null;
                    }
                    return (
                      <tr key={user.id}>
                        <td className="px-4 py-2 border">{user.name}</td>
                        <td className="px-4 py-2 border">{user.role}</td>
                        <td className="px-4 py-2 border">{format(selectedDate, "yyyy-MM-dd")}</td>
                        <td className={`px-4 py-2 border ${getStatusColor(status)}`}>
                          {status.charAt(0).toUpperCase() + status.slice(1)}
                        </td>
                        <td className="px-4 py-2 border">{attendanceRecord?.checkInTime || "N/A"}</td>
                        <td className="px-4 py-2 border">{attendanceRecord?.checkOutTime || "N/A"}</td>
                        <td className="px-4 py-2 border">
                          {attendanceRecord?.workMode === "onSite"
                            ? `On Site (${attendanceRecord.latitude?.toFixed(4)}, ${attendanceRecord.longitude?.toFixed(4)})`
                            : attendanceRecord?.workMode || "N/A"}
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          )}
        </div>

      </div>
    </DashboardLayout>
  );
};

export default AllAttendancePage;