import React, { useState, useEffect } from "react";
import { useAuth } from "../../Pages/AuthContext";
import { collection, addDoc, query, where, getDocs, updateDoc, doc } from "firebase/firestore";
import { database } from "../Firebase/firebase";
import Calendar from "react-calendar";
import { Value } from "react-calendar/dist/cjs/shared/types";
import "react-calendar/dist/Calendar.css";
import { format, isToday, isAfter, parse } from "date-fns";
import DashboardLayout from "../../layouts/dashboardlayout";
import { MdLocationOn, MdAccessTime, MdEvent, MdPerson, MdWork, MdSettingsSuggest, MdCalendarMonth, MdListAlt } from 'react-icons/md';
import { BeatLoader } from 'react-spinners'; // Import a spinner component


interface AuthContextType {
    user: any;
    userName: string;
    role: string;
}

interface AttendanceRecord {
    id?: string;
    date: string;
    checkInTime?: string;
    checkOutTime?: string;
    status: string;
    workMode: "onShoot" | "onSite";
    latitude?: number | null;
    longitude?: number | null;
}

export default function MediaAttendancePage() {
    const [showCalendar, setShowCalendar] = useState(false);
    const { user, userName, role } = useAuth();
    const [currentTime, setCurrentTime] = useState(new Date());
    const [latitude, setLatitude] = useState<number | null>(null);
    const [longitude, setLongitude] = useState<number | null>(null);
    const [workMode, setWorkMode] = useState<"onShoot" | "onSite">("onShoot");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [attendance, setAttendance] = useState<AttendanceRecord[]>([]);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [isAttendanceLoading, setIsAttendanceLoading] = useState(false); // Loading state for buttons


    useEffect(() => {
        if (workMode === "onShoot" && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLatitude(position.coords.latitude);
                    setLongitude(position.coords.longitude);
                },
                (error) => {
                    setError("Unable to retrieve your location.");
                    console.error("Geolocation error:", error);
                }
            );
        } else {
            setLatitude(null);
            setLongitude(null);
        }
    }, [workMode]);

    useEffect(() => {
        const timer = setInterval(() => setCurrentTime(new Date()), 1000);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        const fetchAttendance = async () => {
            if (user) {
                const q = query(
                    collection(database, "attendance"),
                    where("userId", "==", user.uid)
                );
                const querySnapshot = await getDocs(q);
                const attendanceData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    date: doc.data().date,
                    checkInTime: doc.data().checkInTime || null,
                    checkOutTime: doc.data().checkOutTime || null,
                    status: doc.data().status,
                    workMode: doc.data().workMode || "N/A",
                    latitude: doc.data().latitude || null,
                    longitude: doc.data().longitude || null,
                }));
                setAttendance(attendanceData);
            }
        };
        fetchAttendance();
    }, [user]);

    const handleMarkAttendance = async (action: "checkIn" | "checkOut") => {
        if (workMode === "onShoot" && (!latitude || !longitude)) {
            setError("Unable to retrieve your location.");
            return;
        }

        const today = format(currentTime, "yyyy-MM-dd");
        const existingRecord = attendance.find((record) => record.date === today);

        if (action === "checkIn" && existingRecord?.checkInTime) {
            setError("You have already checked in today.");
            return;
        }

        if (action === "checkOut" && (!existingRecord || existingRecord.checkOutTime)) {
            setError("You have already checked out today.");
            return;
        }

        setIsAttendanceLoading(true); // Start loading
        setError(""); // Clear previous errors
        setSuccess(""); // Clear previous success messages

        try {
            const time = format(currentTime, "hh:mm:ss a");
            const status = isAfter(currentTime, parse("11:16 AM", "hh:mm a", currentTime)) ? "late" : "present";

            if (action === "checkIn") {
                const docRef = await addDoc(collection(database, "attendance"), {
                    userId: user?.uid,
                    name: userName || "Unknown",
                    date: today,
                    checkInTime: time,
                    status,
                    workMode,
                    latitude: workMode === "onShoot" ? latitude : null,
                    longitude: workMode === "onShoot" ? longitude : null,
                });

                setAttendance([
                    ...attendance,
                    {
                        id: docRef.id,
                        date: today,
                        checkInTime: time,
                        status,
                        workMode,
                        latitude,
                        longitude,
                    },
                ]);
                setSuccess("Checked in successfully!");
            } else if (action === "checkOut" && existingRecord && existingRecord.id) {
                await updateDoc(doc(database, "attendance", existingRecord.id), {
                    checkOutTime: time,
                });

                setAttendance(
                    attendance.map((record) =>
                        record.date === today ? { ...record, checkOutTime: time } : record
                    )
                );
                setSuccess("Checked out successfully!");
            }

        } catch (err: any) {
            setError("Failed to mark attendance. Please try again.");
            console.error("Error marking attendance:", err);
        } finally {
            setIsAttendanceLoading(false); // End loading in finally block to ensure it always resets
        }
    };

    const handleDateChange = (value: Value) => {
        if (value instanceof Date) {
            setSelectedDate(value);
        } else if (Array.isArray(value) && value[0] instanceof Date) {
            setSelectedDate(value[0]);
        }
    };

    const tileContent = ({ date }: { date: Date }) => {
        const dateString = format(date, "yyyy-MM-dd");
        const record = attendance.find((record) => record.date === dateString);

        if (!record) {
            return (
                <div className="flex justify-center items-center h-6 w-6 rounded-full bg-red-500 text-white text-xs">
                    {date.getDate()}
                </div>
            );
        }

        let backgroundColorClass = "bg-green-500";
        let textColorClass = "text-white";
        let label = "Present";

        if (record.status === "late") {
            backgroundColorClass = "bg-orange-500";
            label = "Late";
        } else if (record.workMode === "onSite") {
            backgroundColorClass = "bg-blue-500";
            label = "WFH";
        }

        return (
            <div className={`flex justify-center items-center h-6 w-6 rounded-full ${backgroundColorClass} ${textColorClass} text-xs`}>
                {date.getDate()}
            </div>
        );
    };


    return (
        <DashboardLayout title="User Attendance" showBackButton>
            <div className="min-h-screen  p-6 pt-20">
                <div className="container mx-auto max-w-5xl">
                    <h1 className="text-3xl font-bold text-gray-800 mb-8 flex items-center gap-2"><MdEvent className="inline-block mr-1" />Attendance Dashboard</h1>

                    {/* User Information Card */}
                    <div className="bg-white shadow rounded-2xl p-6 mb-8">
                        <h2 className="text-xl font-semibold text-gray-700 mb-5 flex items-center gap-2"><MdPerson className="inline-block mr-2 text-gray-500" /> User Information</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <p className="text-gray-600 font-semibold flex items-center gap-2"><MdPerson className="inline-block mr-1 text-gray-500" /> <span className="text-gray-800">Name:</span> {userName}</p>
                            </div>
                            <div>
                                <p className="text-gray-600 font-semibold flex items-center gap-2"><MdWork className="inline-block mr-1 text-gray-500" /> <span className="text-gray-800">Role:</span> {role}</p>
                            </div>
                        </div>
                    </div>

                    {/* Work Mode and Current Details */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
                        {/* Work Mode Card */}
                        <div className="bg-white shadow rounded-2xl p-6">
                            <h2 className="text-xl font-semibold text-gray-700 mb-5 flex items-center gap-2"><MdSettingsSuggest className="inline-block mr-2 text-gray-500" /> Work Mode</h2>
                            <div className="space-y-4">
                                <label className="flex items-center text-gray-600 font-semibold">
                                    <input
                                        type="radio"
                                        name="workMode"
                                        value="onShoot"
                                        checked={workMode === "onShoot"}
                                        onChange={() => setWorkMode("onShoot")}
                                        className="mr-2 focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                                    />
                                    On Shoot
                                </label>
                                <label className="flex items-center text-gray-600 font-semibold">
                                    <input
                                        type="radio"
                                        name="workMode"
                                        value="onSite"
                                        checked={workMode === "onSite"}
                                        onChange={() => setWorkMode("onSite")}
                                        className="mr-2 focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                                    />
                                    At Office
                                </label>
                            </div>
                        </div>

                        {/* Current Details Card */}
                        <div className="bg-white shadow rounded-2xl p-6">
                            <h2 className="text-xl font-semibold text-gray-700 mb-5 flex items-center gap-2"><MdAccessTime className="inline-block mr-2 text-gray-500" /> Current Details</h2>
                            <div className="space-y-3">
                                <p className="text-gray-600 font-semibold flex items-center gap-2"><MdAccessTime className="inline-block mr-1 text-gray-500" /><span className="text-gray-800">Current Time:</span> {format(currentTime, "hh:mm:ss a")}</p>
                                {workMode === "onShoot" && (
                                    <p className="text-gray-600 font-semibold flex items-center gap-2"><MdLocationOn className="inline-block mr-1 text-gray-500" /><span className="text-gray-800">Your Location:</span>
                                        {latitude && longitude ? (
                                            <> Latitude: {latitude.toFixed(6)}, Longitude: {longitude.toFixed(6)}</>
                                        ) : (
                                            " Fetching location..."
                                        )}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="flex justify-start space-x-4 mb-8">
                        <button
                            onClick={() => handleMarkAttendance("checkIn")}
                            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-xl shadow-md transition-colors duration-200 disabled:opacity-50"
                            disabled={isAttendanceLoading} // Disable when loading
                        >
                            {isAttendanceLoading ? (
                                <span className="flex items-center gap-2">
                                    <BeatLoader color="white" size={8} /> Processing...
                                </span>
                            ) : (
                                "Check In"
                            )}
                        </button>
                        <button
                            onClick={() => handleMarkAttendance("checkOut")}
                            className="bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-6 rounded-xl shadow-md transition-colors duration-200 disabled:opacity-50"
                            disabled={isAttendanceLoading} // Disable when loading
                        >
                            {isAttendanceLoading ? (
                                <span className="flex items-center gap-2">
                                    <BeatLoader color="white" size={8} /> Processing...
                                </span>
                            ) : (
                                "Check Out"
                            )}
                        </button>
                    </div>

                    {/* Messages */}
                    {error && (
                        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-md mb-6">
                            <p>{error}</p>
                        </div>
                    )}
                    {success && (
                        <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 rounded-md mb-6">
                            <p>{success}</p>
                        </div>
                    )}

                    {/* Attendance Calendar Card */}
                    <div className="bg-white shadow rounded-2xl p-6 mb-8">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-semibold text-gray-700 flex items-center gap-2"><MdCalendarMonth className="inline-block mr-2 text-gray-500" /> Attendance Calendar</h2>
                            <button
                                onClick={() => setShowCalendar(!showCalendar)}
                                className="text-blue-600 hover:underline focus:outline-none"
                            >
                                {showCalendar ? "Hide Calendar" : "Show Calendar"}
                            </button>
                        </div>
                        {showCalendar && (
                            <div className="mt-2">
                                <Calendar
                                    onChange={handleDateChange}
                                    value={selectedDate}
                                    tileContent={tileContent}
                                />
                            </div>
                        )}
                    </div>

                    {/* Attendance Records Table Card */}
                    <div className="bg-white shadow rounded-2xl p-6">
                        <h2 className="text-xl font-semibold text-gray-700 mb-5 flex items-center gap-2"><MdListAlt className="inline-block mr-2 text-gray-500" /> Attendance Records</h2>
                        <div className="overflow-x-auto">
                            <table className="min-w-full border border-gray-200 rounded-lg">
                                <thead className="bg-gray-100">
                                    <tr>
                                        <th className="py-3 px-4 text-left text-sm font-semibold text-gray-600">Date</th>
                                        <th className="py-3 px-4 text-left text-sm font-semibold text-gray-600">Check-in Time</th>
                                        <th className="py-3 px-4 text-left text-sm font-semibold text-gray-600">Check-out Time</th>
                                        <th className="py-3 px-4 text-left text-sm font-semibold text-gray-600">Status</th>
                                        <th className="py-3 px-4 text-left text-sm font-semibold text-gray-600">Work Mode</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {attendance.map((record, index) => (
                                        <tr
                                            key={index}
                                            className={`hover:bg-gray-50 ${index % 2 === 0 ? "bg-gray-50" : "bg-white"}`}
                                        >
                                            <td className="py-3 px-4 text-sm text-gray-700">{record.date}</td>
                                            <td className="py-3 px-4 text-sm text-gray-700">{record.checkInTime || "N/A"}</td>
                                            <td className="py-3 px-4 text-sm text-gray-700">{record.checkOutTime || "N/A"}</td>
                                            <td className="py-3 px-4 text-sm text-gray-700">{record.status}</td>
                                            <td className="py-3 px-4 text-sm text-gray-700">{record.workMode}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
};