import React, { useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import DashboardLayout from "../layouts/dashboardlayout";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { database } from "../components/Firebase/firebase";

interface UserDetails {
  id: string;
  name: string;
  email: string;
  leaveBalances?: {
    sick: number;
    annual: number;
    casual: number;
    unpaid: number;
  };
}

const LeaveSubmit: React.FC = () => {
  const { user, role } = useAuth();
  const [users, setUsers] = useState<UserDetails[]>([]);
  const [selectedUser, setSelectedUser] = useState<UserDetails | null>(null);
  const [leaveBalances, setLeaveBalances] = useState<{
    sick: number;
    annual: number;
    casual: number;
    unpaid: number;
  }>({ sick: 0, annual: 0, casual: 0, unpaid: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // Fetch all users
  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      if (user && role === "am") {
        try {
          const usersCollection = collection(database, "users");
          const querySnapshot = await getDocs(usersCollection);
          const fetchedUsers = querySnapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              id: doc.id,
              name: data.name,
              email: data.email,
              leaveBalances: data.leaveBalances || {
                sick: 0,
                annual: 0,
                casual: 0,
                unpaid: 0,
              },
            };
          }) as UserDetails[];
          setUsers(fetchedUsers);
        } catch (err) {
          setError("Failed to fetch users.");
          console.error("Error fetching users:", err);
        }
      } else if (user && role !== "am") {
        setError("You do not have the correct role to access this page.");
      }
      setLoading(false);
    };

    fetchUsers();
  }, [user, role]);

  // Fetch user details and set leave balances
  const handleUserSelect = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const userId = e.target.value;
    if (userId) {
      setLoading(true);
      try {
        const userDoc = await getDoc(doc(database, "users", userId));
        if (userDoc.exists()) {
          const userData = userDoc.data() as UserDetails;
          setSelectedUser(userData);
          setLeaveBalances(
            userData.leaveBalances || {
              sick: 0,
              annual: 0,
              casual: 0,
              unpaid: 0,
            }
          );
        }
      } catch (err) {
        setError("Failed to fetch user details.");
        console.error("Error fetching user details:", err);
      }
      setLoading(false);
    } else {
      setSelectedUser(null);
      setLeaveBalances({ sick: 0, annual: 0, casual: 0, unpaid: 0 });
    }
  };

  // Update leave balances in Firestore
  const handleUpdate = async () => {
    if (selectedUser?.id) {
      const confirmUpdate = window.confirm("Are you sure you want to update the leave balances?");
      if (confirmUpdate) {
        setLoading(true);
        try {
          const userRef = doc(database, "users", selectedUser.id);

          // Ensure leaveBalances field exists in the user document
          const userDoc = await getDoc(userRef);
          const userData = userDoc.data();

          const updatedLeaveBalances = {
            sick: leaveBalances.sick,
            annual: leaveBalances.annual,
            casual: leaveBalances.casual,
            unpaid: leaveBalances.unpaid,
          };

          // Update the document with leaveBalances
          await updateDoc(userRef, {
            leaveBalances: updatedLeaveBalances,
          });

          setSuccess("Leave balances updated successfully!");
          setError("");
          console.log(
            "Leave balances updated successfully for user:",
            selectedUser.name,
            updatedLeaveBalances
          );
        } catch (err) {
          setError("Failed to update leave balances.");
          console.error("Error updating leave balances:", err);
        }
        setLoading(false);
      }
    }
  };

  if (loading) {
    return (
      <DashboardLayout title="Update Leave">
        <div>Loading...</div>
      </DashboardLayout>
    );
  }
 
  return (
    <DashboardLayout title="Update Leave">
      
        <h1 className="text-3xl font-bold text-gray-800 mb-6">Update Leave</h1>

        {/* User Selection */}
        <div className="bg-white shadow-md rounded-2xl p-6 mb-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">
            Select User
          </h2>
          <select
            onChange={handleUserSelect}
            className="w-full p-2 border border-gray-300 rounded-lg"
            defaultValue=""
          >
            <option value="" disabled>
              Select a user
            </option>
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.name} ({user.email})
              </option>
            ))}
          </select>
        </div>
        {error && (
          <div className="mt-4 bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-lg">
            {error}
          </div>
        )}
        {/* Display User Details and leave */}
        {selectedUser && (
          <div className="bg-white shadow-md rounded-2xl p-6 mb-6">
            <h2 className="text-xl font-semibold text-gray-700 mb-4">
              User Details
            </h2>
            <p className="text-gray-600 mb-2">
              <strong>Name:</strong> {selectedUser.name}
            </p>
            <p className="text-gray-600 mb-2">
              <strong>Email:</strong> {selectedUser.email}
            </p>
            <h2 className="text-xl font-semibold text-gray-700 mb-4">
              Remaining Leave Balances
            </h2>
            <div className="grid gap-4 sm:grid-cols-4">
              <div>
                <p className="text-lg text-gray-600">
                  Sick Leaves:{" "}
                  <span className="font-semibold">{leaveBalances?.sick}</span>
                </p>
              </div>
              <div>
                <p className="text-lg text-gray-600">
                  Annual Leaves:{" "}
                  <span className="font-semibold">{leaveBalances?.annual}</span>
                </p>
              </div>
              <div>
                <p className="text-lg text-gray-600">
                  Casual Leaves:{" "}
                  <span className="font-semibold">{leaveBalances?.casual}</span>
                </p>
              </div>
              <div>
                <p className="text-lg text-gray-600">
                  Unpaid Leaves:{" "}
                  <span className="font-semibold">{leaveBalances?.unpaid}</span>
                </p>
              </div>
            </div>
          </div>
        )}

        {/* Leave Balances Form */}
        {selectedUser && (
          <div className="bg-white shadow-md rounded-2xl p-6 mb-6">
            <h2 className="text-xl font-semibold text-gray-700 mb-4">
              Update Remaining Leave Balances
            </h2>
            <div className="grid gap-4 sm:grid-cols-2">
              <div>
                <label className="block text-gray-600 font-semibold mb-2">
                  Sick Leaves
                </label>
                <input
                  type="number"
                  value={leaveBalances.sick}
                  onChange={(e) =>
                    setLeaveBalances({
                      ...leaveBalances,
                      sick: Number(e.target.value),
                    })
                  }
                  className="w-full p-2 border border-gray-300 rounded-lg"
                />
              </div>
              <div>
                <label className="block text-gray-600 font-semibold mb-2">
                  Annual Leaves
                </label>
                <input
                  type="number"
                  value={leaveBalances.annual}
                  onChange={(e) =>
                    setLeaveBalances({
                      ...leaveBalances,
                      annual: Number(e.target.value),
                    })
                  }
                  className="w-full p-2 border border-gray-300 rounded-lg"
                />
              </div>
              <div>
                <label className="block text-gray-600 font-semibold mb-2">
                  Casual Leaves
                </label>
                <input
                  type="number"
                  value={leaveBalances.casual}
                  onChange={(e) =>
                    setLeaveBalances({
                      ...leaveBalances,
                      casual: Number(e.target.value),
                    })
                  }
                  className="w-full p-2 border border-gray-300 rounded-lg"
                />
              </div>
              <div>
                <label className="block text-gray-600 font-semibold mb-2">
                  Unpaid Leaves
                </label>
                <input
                  type="number"
                  value={leaveBalances.unpaid}
                  onChange={(e) =>
                    setLeaveBalances({
                      ...leaveBalances,
                      unpaid: Number(e.target.value),
                    })
                  }
                  className="w-full p-2 border border-gray-300 rounded-lg"
                />
              </div>
            </div>
            <button
              onClick={handleUpdate}
              className="mt-6 bg-blue-600 text-white px-6 py-3 rounded-lg shadow hover:bg-blue-700 transition"
            >
              Update Leaves
            </button>
            {success && (
              <div className="mt-4 bg-green-100 border-l-4 border-green-500 text-green-700 p-4 rounded-lg">
                {success}
              </div>
            )}
          </div>
        )}
  
    </DashboardLayout>
  );
};

export default LeaveSubmit;