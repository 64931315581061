import { differenceInDays, isWeekend, startOfDay, endOfDay } from "date-fns";

export const calculateWorkingDays = (startDate: Date, endDate: Date): number => {
    let workingDays = 0;
    let currentDate = startOfDay(startDate);
    const end = endOfDay(endDate);

  while (currentDate <= end) {
      if (!isWeekend(currentDate)) {
          workingDays++;
      }
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
  }
  return workingDays;
};