import React, { useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import DashboardLayout from "../layouts/dashboardlayout";
import {
  collection,
  addDoc,
  doc,
  getDoc,
  updateDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { database } from "../components/Firebase/firebase";
import { format, parseISO } from "date-fns";
import { calculateWorkingDays } from "../utils/utils";

interface LeaveRecord {
  id?: string;
  userId: string;
  startDate: string;
  endDate: string;
  leaveType: string;
  reason: string;
  status: "pending" | "approved" | "rejected" | "unpaid" | "extra";
  createdAt: string;
  leaveDays: number;
}

interface UserDetails {
  name: string;
  email: string;
  leaveBalances: {
    sick: number;
    annual: number;
    casual: number;
    unpaid: number;
    extra: number; // Added extra leave type
  };
}

const LeavesForm: React.FC = () => {
  const { user } = useAuth();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [leaveType, setLeaveType] = useState("casual"); // Default leave type
  const [reason, setReason] = useState("");
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [leaveHistory, setLeaveHistory] = useState<LeaveRecord[]>([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const defaultLeaveBalances = {
    sick: 8,
    annual: 12,
    casual: 7,
    unpaid: 0,
    extra: 0, // Added extra leave type with default 0
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (user) {
        const userRef = doc(database, "users", user.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          const leaveBalances = {
            sick: userData.leaveBalances?.sick ?? defaultLeaveBalances.sick,
            annual: userData.leaveBalances?.annual ?? defaultLeaveBalances.annual,
            casual: userData.leaveBalances?.casual ?? defaultLeaveBalances.casual,
            unpaid: userData.leaveBalances?.unpaid ?? defaultLeaveBalances.unpaid,
            extra: userData.leaveBalances?.extra ?? defaultLeaveBalances.extra, // Get or default extra
          };

          setUserDetails({
            name: userData.name || "Unnamed User",
            email: userData.email || "No Email Provided",
            leaveBalances,
          });

          if (!userData.leaveBalances) {
            await updateDoc(userRef, { leaveBalances });
          } else if (userData.leaveBalances && userData.leaveBalances.extra === undefined) { // Ensure 'extra' exists in DB
            await updateDoc(userRef, { 'leaveBalances.extra': defaultLeaveBalances.extra });
          }

        } else {
          const initialLeaveBalances = defaultLeaveBalances;
          await updateDoc(userRef, {
            name: user.displayName || "Unnamed User",
            email: user.email || "No Email Provided",
            leaveBalances: initialLeaveBalances,
          });
          setUserDetails({
            name: user.displayName || "",
            email: user.email || "",
            leaveBalances: initialLeaveBalances,
          });
        }
      }
      setLoading(false);
    };

    const fetchLeaveHistory = async () => {
      if (user) {
        const q = query(collection(database, "leaves"), where("userId", "==", user.uid));
        const querySnapshot = await getDocs(q);
        const leaves = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as LeaveRecord[];
        setLeaveHistory(leaves);
      }
    };

    fetchUserDetails();
    fetchLeaveHistory();
  }, [user]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);
    setError("");
    setSuccess("");

    try {
      if (!startDate || !endDate || !leaveType || !reason) {
        setError("Please fill in all fields.");
        setIsSubmitting(false);
        return;
      }

      const start = parseISO(startDate);
      const end = parseISO(endDate);
      const days = calculateWorkingDays(start, end);

      if (!userDetails) {
        setError("User details not loaded.");
        setIsSubmitting(false);
        return;
      }

      const selectedLeaveBalance =
        userDetails.leaveBalances[leaveType as keyof typeof userDetails.leaveBalances] || 0;

      if (leaveType !== "unpaid" && leaveType !== "extra") { // Deduct balance for paid leaves, but not for 'extra'
        if (days > selectedLeaveBalance) {
          setError(`You only have ${selectedLeaveBalance} ${leaveType} leaves remaining.`);
          setIsSubmitting(false);
          return;
        }

        if (selectedLeaveBalance <= 0) {
          setError(`You have no more remaining ${leaveType} leaves.`);
          setIsSubmitting(false);
          return;
        }
      }

      const confirmed = window.confirm("Are you sure you want to submit this leave application?");
      if (!confirmed) {
        setIsSubmitting(false);
        return;
      }

      const leaveData: LeaveRecord = {
        userId: user?.uid!,
        startDate,
        endDate,
        leaveType,
        reason,
        status: "pending",
        createdAt: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        leaveDays: days,
      };

      const leaveRef = await addDoc(collection(database, "leaves"), leaveData);

      if (leaveType !== "unpaid" && leaveType !== "extra") { // Deduct balance only for paid leave types
        const userRef = doc(database, "users", user.uid);
        const updatedBalances = { ...userDetails.leaveBalances };
        updatedBalances[leaveType as keyof typeof updatedBalances] -= days;

        await updateDoc(userRef, { leaveBalances: updatedBalances });
        setUserDetails((prevState) => ({
          ...prevState!,
          leaveBalances: updatedBalances,
        }));
      } else if (leaveType === "extra") {
          // For 'extra' leave, no balance deduction, maybe handle differently in admin approval if needed
      }


      setSuccess("Leave application submitted successfully!");
      setStartDate("");
      setEndDate("");
      setLeaveType("casual"); // Optionally reset to default
      setReason("");
    } catch (err) {
      setError("Failed to submit leave application. Please try again.");
      console.error("Error submitting leave:", err);
    } finally {
      setIsSubmitting(false);
    }
  };


  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <DashboardLayout title="Leaves" showBackButton>
      <div className="min-h-screen  p-6 pt-20">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">Leaves Application Form</h1>

        <div className="bg-white shadow-md rounded-2xl p-6 mb-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">User Details</h2>
          <div className="grid gap-4 sm:grid-cols-2">
            <div>
              <label className="block text-gray-600 font-semibold mb-2">Name</label>
              <input
                type="text"
                value={userDetails?.name || ""}
                className="w-full p-2 border border-gray-300 rounded-lg bg-gray-100"
                readOnly
              />
            </div>
            <div>
              <label className="block text-gray-600 font-semibold mb-2">Email</label>
              <input
                type="email"
                value={userDetails?.email || ""}
                className="w-full p-2 border border-gray-300 rounded-lg bg-gray-100"
                readOnly
              />
            </div>
          </div>
        </div>

        <div className="bg-white shadow-md rounded-2xl p-6 mb-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Leave Balances</h2>
          <div className="grid gap-4 sm:grid-cols-5"> {/* Modified to 5 columns */}
            <div>
              <p className="text-lg text-gray-600">
                Sick Leaves: <span className="font-semibold">{userDetails?.leaveBalances.sick}</span>
              </p>
            </div>
            <div>
              <p className="text-lg text-gray-600">
                Annual Leaves: <span className="font-semibold">{userDetails?.leaveBalances.annual}</span>
              </p>
            </div>
            <div>
              <p className="text-lg text-gray-600">
                Casual Leaves: <span className="font-semibold">{userDetails?.leaveBalances.casual}</span>
              </p>
            </div>
            <div>
              <p className="text-lg text-gray-600">
                Unpaid Leaves: <span className="font-semibold">{userDetails?.leaveBalances.unpaid}</span>
              </p>
            </div>
             <div>
              <p className="text-lg text-gray-600">
                Extra Leaves: <span className="font-semibold">{userDetails?.leaveBalances.extra}</span>
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white shadow-md rounded-2xl p-6 mb-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Apply for Leave</h2>
          <form onSubmit={handleSubmit}>
            <div className="grid gap-4 sm:grid-cols-2">
              <div>
                <label className="block text-gray-600 font-semibold mb-2">Start Date</label>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-lg"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-600 font-semibold mb-2">End Date</label>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-lg"
                  required
                />
              </div>
            </div>
            <div className="mt-4">
              <label className="block text-gray-600 font-semibold mb-2">Reason</label>
              <textarea
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-lg"
                rows={4}
                required
              />
            </div>
             {/* Hidden leave type input, defaults to casual */}
             <input type="hidden" name="leaveType" value={leaveType} />

            <button
              type="submit"
              disabled={isSubmitting}
              className={`mt-6 bg-blue-600 text-white px-6 py-3 rounded-lg shadow transition ${
                isSubmitting ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700"
              }`}
            >
              {isSubmitting ? (
                <div className="flex items-center justify-center">
                  <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                      fill="none"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                  Processing...
                </div>
              ) : (
                "Submit Leave Application"
              )}
            </button>
            {error && (
              <div className="mt-4 bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-lg">
                {error}
              </div>
            )}
            {success && (
              <div className="mt-4 bg-green-100 border-l-4 border-green-500 text-green-700 p-4 rounded-lg">
                {success}
              </div>
            )}
          </form>
        </div>

        <div className="bg-white shadow-md rounded-2xl p-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Leave History</h2>
          <table className="min-w-full border border-gray-200 rounded-lg">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-3 px-4 text-left text-sm text-gray-600 font-semibold">Start Date</th>
                <th className="py-3 px-4 text-left text-sm text-gray-600 font-semibold">End Date</th>
                <th className="py-3 px-4 text-left text-sm text-gray-600 font-semibold">Leave Type</th>
                <th className="py-3 px-4 text-left text-sm text-gray-600 font-semibold">Reason</th>
                <th className="py-3 px-4 text-left text-sm text-gray-600 font-semibold">Status</th>
              </tr>
            </thead>
            <tbody>
              {leaveHistory.map((leave, index) => (
                <tr
                  key={index}
                  className={`hover:bg-gray-50 ${index % 2 === 0 ? "bg-gray-50" : "bg-white"}`}
                >
                  <td className="py-3 px-4 text-sm text-gray-700">{leave.startDate}</td>
                  <td className="py-3 px-4 text-sm text-gray-700">{leave.endDate}</td>
                  <td className="py-3 px-4 text-sm text-gray-700">{leave.leaveType}</td>
                  <td className="py-3 px-4 text-sm text-gray-700">{leave.reason}</td>
                  <td className="py-3 px-4 text-sm text-gray-700">
                    <span
                      className={`px-2 py-1 rounded-full text-sm font-semibold ${
                        leave.status === "approved"
                          ? "bg-green-100 text-green-700"
                          : leave.status === "rejected"
                          ? "bg-red-100 text-red-700"
                          : leave.status === "unpaid"
                          ? "bg-blue-100 text-blue-700"
                          : leave.status === "extra"
                          ? "bg-purple-100 text-purple-700" // Style for 'extra'
                          : "bg-yellow-100 text-yellow-700"
                      }`}
                    >
                      {leave.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default LeavesForm;