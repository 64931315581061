import React, { useState, useEffect } from "react";
import { useAuth } from "../../Pages/AuthContext";
import { collection, query, where, onSnapshot, doc, setDoc, updateDoc, getDoc } from "firebase/firestore";
import { database } from "../Firebase/firebase";
import Calendar from "react-calendar";
import { Value } from "react-calendar/dist/cjs/shared/types";
import "react-calendar/dist/Calendar.css";
import { format, startOfMonth, endOfMonth, getDaysInMonth, eachDayOfInterval, addMonths, isAfter, parse } from "date-fns";
import DashboardLayout from "../../layouts/dashboardlayout";
import Modal from "../../layouts/model";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { FaCheck, FaSignOutAlt, FaPencilAlt } from 'react-icons/fa'; // Import icons
import { toast } from 'react-toastify'; // Import toast for notifications
import 'react-toastify/dist/ReactToastify.css'; // Import toast styles

const UserAttendancePage = () => {
  const { user, userName, role } = useAuth();
  const [attendance, setAttendance] = useState<
    {
      id?: string; // Include id in the type
      userId: string;
      date: string;
      checkInTime?: string;
      checkOutTime?: string;
      status: string;
      workMode: string;
      latitude?: number;
      longitude?: number;
    }[]
  >([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // State for edit modal
  const [editingAttendanceRecord, setEditingAttendanceRecord] = useState<any>(null); // State to hold record being edited
  const [allUsers, setAllUsers] = useState<any[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date());
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [currentMonthDates, setCurrentMonthDates] = useState<Date[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Fetch all users
    const fetchAllUsers = async () => {
      setIsLoading(true);
      try {
        const usersQuery = query(collection(database, "users"));
        const unsubscribe = onSnapshot(usersQuery, (querySnapshot) => {
          const usersData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setAllUsers(usersData);
          setIsLoading(false);
        });
        return unsubscribe;
      } catch (error) {
        console.error("Error fetching users:", error);
        setIsLoading(false);
      }
    };

    fetchAllUsers();
  }, []);

  useEffect(() => {
    // Fetch attendance in real-time
    const fetchRealTimeAttendance = async () => {
      setIsLoading(true);
      try {
        const q = query(collection(database, "attendance"));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const attendanceData = querySnapshot.docs.map((doc) => ({
            id: doc.id, // Include document ID
            userId: doc.data().userId,
            date: doc.data().date,
            checkInTime: doc.data().checkInTime || null,
            checkOutTime: doc.data().checkOutTime || null,
            status: doc.data().status,
            workMode: doc.data().workMode || "N/A",
            latitude: doc.data().latitude || null,
            longitude: doc.data().longitude || null,
          }));
          setAttendance(attendanceData);
          setIsLoading(false);
        });

        return unsubscribe;
      } catch (error) {
        console.error("Error fetching attendance:", error);
        setIsLoading(false);
      }
    };

    fetchRealTimeAttendance();
  }, []);

  useEffect(() => {
    // Generate all dates of the selected month
    const startDate = startOfMonth(selectedMonth);
    const endDate = endOfMonth(selectedMonth);
    const dates = eachDayOfInterval({ start: startDate, end: endDate });
    setCurrentMonthDates(dates);

    if (selectedDate.getMonth() !== selectedMonth.getMonth()) {
      setSelectedDate(startDate);
    }
  }, [selectedMonth]);

  const handleMarkAttendance = async (userId: string, workMode: string, action: "checkIn" | "checkOut") => {
    const today = format(new Date(), "yyyy-MM-dd");
    const time = format(new Date(), "hh:mm:ss a");
    const attendanceRef = doc(database, "attendance", `${userId}_${today}`);

    try {
      let latitude: number | null = null;
      let longitude: number | null = null;

      if (workMode === "onSite") {
        const position = await new Promise<GeolocationPosition>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;
      }

      const isLate = isAfter(new Date(), parse("11:15 AM", "hh:mm a", new Date()));
      const status = isLate ? "late" : "present";

      if (action === "checkIn") {
        await setDoc(attendanceRef, {
          userId,
          date: today,
          checkInTime: time,
          status,
          workMode,
          latitude: workMode === "onSite" ? latitude : null,
          longitude: workMode === "onSite" ? longitude : null,
        });
        toast.success('Check-in marked successfully!');
      } else if (action === "checkOut") {
        await updateDoc(attendanceRef, {
          checkOutTime: time,
        });
        toast.success('Check-out marked successfully!');
      }

      console.log(`Attendance ${action} marked for:`, userId);
    } catch (error) {
      console.error("Error marking attendance:", error);
      toast.error('Failed to mark attendance.');
    }
  };

  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedMonthValue = new Date(event.target.value);
    setSelectedMonth(selectedMonthValue);
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedDateValue = new Date(event.target.value);
    setSelectedDate(selectedDateValue);
  };

  const getAttendanceForSelectedDate = (userId: string) => {
    const selectedDateString = format(selectedDate, "yyyy-MM-dd");
    return attendance.find((record) => record.userId === userId && record.date === selectedDateString) || null;
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "present":
        return "text-green-500";
      case "late":
        return "text-yellow-500";
      case "absent":
        return "text-red-500";
      default:
        return "text-gray-500";
    }
  };

  // Function to open edit modal and set the record to be edited
  const handleEditAttendance = async (recordId: string) => {
    setIsEditModalOpen(true);
    const attendanceDocRef = doc(database, "attendance", recordId);
    try {
      const docSnap = await getDoc(attendanceDocRef);
      if (docSnap.exists()) {
        setEditingAttendanceRecord({ id: docSnap.id, ...docSnap.data() });
      } else {
        console.log("No such document!");
        toast.error('Attendance record not found.');
        setIsEditModalOpen(false);
      }
    } catch (error) {
      console.error("Error fetching attendance record for edit:", error);
      toast.error('Failed to fetch attendance record for edit.');
      setIsEditModalOpen(false);
    }
  };

  // Function to handle saving edited attendance data
  const handleSaveEditedAttendance = async (editedData: any) => {
    if (!editingAttendanceRecord?.id) {
      console.error("No attendance record ID to update.");
      toast.error('No attendance record ID to update.');
      return;
    }
    const attendanceDocRef = doc(database, "attendance", editingAttendanceRecord.id);
    try {
      await updateDoc(attendanceDocRef, editedData);
      toast.success('Attendance record updated successfully!');
      setIsEditModalOpen(false);
      setEditingAttendanceRecord(null); // Clear editing record after save
    } catch (error) {
      console.error("Error updating attendance record:", error);
      toast.error('Failed to update attendance record.');
    }
  };


  return (
    <DashboardLayout title="User Attendance" showBackButton>
      <div className="min-h-screen  p-6">
        <h1 className="text-2xl font-bold mb-6">Team Attendance</h1>

        {/* Month and Date Dropdowns */}
        <div className="mb-6 flex gap-4">
          {/* Month Dropdown */}
          <div className="flex-1">
            <label htmlFor="month-select" className="block text-sm font-medium text-gray-700">
              Select Month
            </label>
            <select
              id="month-select"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm"
              value={selectedMonth.toISOString()}
              onChange={handleMonthChange}
            >
              {Array.from({ length: 12 }, (_, i) => {
                const monthDate = addMonths(new Date(), i - new Date().getMonth());
                return (
                  <option key={i} value={monthDate.toISOString()}>
                    {format(monthDate, "MMMM yyyy")}
                  </option>
                );
              })}
            </select>
          </div>

          {/* Date Dropdown */}
          <div className="flex-1">
            <label htmlFor="date-select" className="block text-sm font-medium text-gray-700">
              Select Date
            </label>
            <select
              id="date-select"
              className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm"
              value={selectedDate.toISOString()}
              onChange={handleDateChange}
            >
              {currentMonthDates.map((date, index) => (
                <option key={index} value={date.toISOString()}>
                  {format(date, "yyyy-MM-dd")}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Attendance Table */}
        <div className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h2 className="text-xl font-semibold mb-4">Attendance Details</h2>
          {isLoading ? (
            <div className="text-center">Loading...</div>
          ) : (
            <table className="w-full table-auto border-collapse border border-gray-200">
              <thead>
                <tr>
                  <th className="px-4 py-2 border">Name</th>
                  <th className="px-4 py-2 border">Role</th>
                  <th className="px-4 py-2 border">Date</th>
                  <th className="px-4 py-2 border">Attendance</th>
                  <th className="px-4 py-2 border">Check-in Time</th>
                  <th className="px-4 py-2 border">Check-out Time</th>
                  <th className="px-4 py-2 border">Work Mode</th>
                  {role === 'admin' && <th className="px-4 py-2 border">Actions</th>} {/* Conditionally render Actions for admin */}
                </tr>
              </thead>
              <tbody>
                {allUsers.map((user) => {
                  const attendanceRecord = getAttendanceForSelectedDate(user.id);
                  const status = attendanceRecord?.status || "absent";
                  return (
                    <tr key={user.id}>
                      <td className="px-4 py-2 border">{user.name}</td>
                      <td className="px-4 py-2 border">{user.role}</td>
                      <td className="px-4 py-2 border">{format(selectedDate, "yyyy-MM-dd")}</td>
                      <td className={`px-4 py-2 border ${getStatusColor(status)}`}>
                        {status === "present" ? "Present" : status === "late" ? "Late" : "Absent"}
                      </td>
                      <td className="px-4 py-2 border">{attendanceRecord?.checkInTime || "N/A"}</td>
                      <td className="px-4 py-2 border">{attendanceRecord?.checkOutTime || "N/A"}</td>
                      <td className="px-4 py-2 border">
                        {attendanceRecord?.workMode === "onSite"
                          ? `On Site (Lat: ${attendanceRecord.latitude?.toFixed(6)}, Long: ${attendanceRecord.longitude?.toFixed(6)})`
                          : attendanceRecord?.workMode || "N/A"}
                      </td>
                      {role === 'admin' && (
                        <td className="px-4 py-2 border">
                          {!attendanceRecord?.checkInTime && (
                            <button
                              onClick={() => handleMarkAttendance(user.id, "onSite", "checkIn")}
                              className="text-blue-500 hover:text-blue-700 mr-2"
                              disabled={!!attendanceRecord?.checkInTime}
                              title="Check In"
                            >
                              <FaCheck />
                            </button>
                          )}
                          {attendanceRecord?.checkInTime && !attendanceRecord?.checkOutTime && (
                            <button
                              onClick={() => handleMarkAttendance(user.id, "onSite", "checkOut")}
                              className="text-green-500 hover:text-green-700"
                              disabled={!attendanceRecord?.checkInTime || !!attendanceRecord?.checkOutTime}
                              title="Check Out"
                            >
                              <FaSignOutAlt />
                            </button>
                          )}
                          <button
                            onClick={() => handleEditAttendance(attendanceRecord?.id || `${user.id}_${format(selectedDate, "yyyy-MM-dd")}`)} // Use combined ID if record exists, or create a potential ID for new record
                            className="text-gray-500 hover:text-gray-700 ml-2"
                            title="Edit Attendance"
                          >
                            <FaPencilAlt />
                          </button>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>

        {/* View All Attendance Button */}
        <div className="mb-6 flex justify-end">
          <button
            onClick={() => setIsModalOpen(true)}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
          >
            View Attendance Calendar
          </button>
        </div>

        {/* Attendance Calendar Popup */}
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-4xl h-[90vh] flex flex-col">
              <div className="p-6 border-b">
                <h2 className="text-xl font-bold mb-4">Attendance Calendar</h2>
                <button
                  onClick={() => setIsModalOpen(false)}
                  className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                >
                  ×
                </button>
              </div>
              <div className="p-6 overflow-y-auto flex-1">
                <Calendar
                  onChange={(value) => setSelectedMonth(value as Date)}
                  value={selectedMonth}
                  view="year"
                  onClickMonth={(value) => setSelectedMonth(value)}
                />
              </div>
            </div>
          </div>
        </Modal>

        {/* Edit Attendance Modal */}
        <Modal isOpen={isEditModalOpen} onClose={() => { setIsEditModalOpen(false); setEditingAttendanceRecord(null); }}>
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-md flex flex-col">
              <div className="p-6 border-b">
                <h2 className="text-xl font-bold mb-4">Edit Attendance</h2>
                <button
                  onClick={() => { setIsEditModalOpen(false); setEditingAttendanceRecord(null); }}
                  className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                >
                  ×
                </button>
              </div>
              <div className="p-6 overflow-y-auto flex-1">
                {editingAttendanceRecord ? (
                  <EditAttendanceForm
                    record={editingAttendanceRecord}
                    onSave={handleSaveEditedAttendance}
                    onCancel={() => { setIsEditModalOpen(false); setEditingAttendanceRecord(null); }}
                  />
                ) : (
                  <div>Loading Edit Form...</div> // Or handle no record to edit state
                )}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </DashboardLayout>
  );
};

// Create a separate component for the Edit Attendance Form
const EditAttendanceForm: React.FC<{ record: any, onSave: (data: any) => void, onCancel: () => void }> = ({ record, onSave, onCancel }) => {
  const [checkInTime, setCheckInTime] = useState(record.checkInTime || "");
  const [checkOutTime, setCheckOutTime] = useState(record.checkOutTime || "");
  const [status, setStatus] = useState(record.status || "absent");
  const [workMode, setWorkMode] = useState(record.workMode || "N/A");
  const [latitude, setLatitude] = useState(record.latitude || "");
  const [longitude, setLongitude] = useState(record.longitude || "");
  const [date, setDate] = useState(record.date || "");
  const [userId, setUserId] = useState(record.userId || "");


  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSave({
      checkInTime,
      checkOutTime,
      status,
      workMode,
      latitude: workMode === 'onSite' ? parseFloat(latitude) : null,
      longitude: workMode === 'onSite' ? parseFloat(longitude) : null,
      date,
      userId
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
        <input type="date" id="date" className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm" value={date} onChange={(e) => setDate(e.target.value)} readOnly />
      </div>
      <div>
        <label htmlFor="userId" className="block text-sm font-medium text-gray-700">User ID</label>
        <input type="text" id="userId" className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm" value={userId} onChange={(e) => setUserId(e.target.value)} readOnly />
      </div>
      <div>
        <label htmlFor="checkInTime" className="block text-sm font-medium text-gray-700">Check-in Time</label>
        <input type="time" id="checkInTime" className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm" value={checkInTime} onChange={(e) => setCheckInTime(e.target.value)} />
      </div>
      <div>
        <label htmlFor="checkOutTime" className="block text-sm font-medium text-gray-700">Check-out Time</label>
        <input type="time" id="checkOutTime" className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm" value={checkOutTime} onChange={(e) => setCheckOutTime(e.target.value)} />
      </div>
      <div>
        <label htmlFor="status" className="block text-sm font-medium text-gray-700">Status</label>
        <select id="status" className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm" value={status} onChange={(e) => setStatus(e.target.value)}>
          <option value="present">Present</option>
          <option value="late">Late</option>
          <option value="absent">Absent</option>
        </select>
      </div>
      <div>
        <label htmlFor="workMode" className="block text-sm font-medium text-gray-700">Work Mode</label>
        <select id="workMode" className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm" value={workMode} onChange={(e) => setWorkMode(e.target.value)}>
          <option value="onSite">On Site</option>
          <option value="remote">Remote</option>
          <option value="N/A">N/A</option>
        </select>
      </div>
      {workMode === 'onSite' && (
        <>
          <div>
            <label htmlFor="latitude" className="block text-sm font-medium text-gray-700">Latitude</label>
            <input type="number" id="latitude" className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm" value={latitude} onChange={(e) => setLatitude(e.target.value)} />
          </div>
          <div>
            <label htmlFor="longitude" className="block text-sm font-medium text-gray-700">Longitude</label>
            <input type="number" id="longitude" className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm" value={longitude} onChange={(e) => setLongitude(e.target.value)} />
          </div>
        </>
      )}

      <div className="flex justify-end gap-4">
        <button type="button" className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded" onClick={onCancel}>
          Cancel
        </button>
        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Save
        </button>
      </div>
    </form>
  );
};


export default UserAttendancePage;