// ExpensesForm.tsx (Updated - File upload removed)
import React, { useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import DashboardLayout from "../layouts/dashboardlayout";
import {
  collection,
  addDoc,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { database } from "../components/Firebase/firebase";
import { format } from "date-fns";

interface ExpenseRecord {
  id?: string;
  userId: string;
  date: string;
  purpose: string;
  description: string;
  category: string;
  cost: number;
  status: "pending" | "approved" | "rejected";
  createdAt: string;
}

interface UserDetails {
  name: string;
  email: string;
}

interface ExpenseItem {
  date: string;
  description: string;
  cost: number | string;
}

const ExpensesForm: React.FC = () => {
  const { user } = useAuth();
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [expenseItems, setExpenseItems] = useState<ExpenseItem[]>([{ date: "", description: "", cost: "" }]);
  const [purpose, setPurpose] = useState("");
  const [category, setCategory] = useState("Fuel expenses"); // Default category
  const [expenseHistory, setExpenseHistory] = useState<ExpenseRecord[]>([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [totalCost, setTotalCost] = useState(0);

  const categories = ["Fuel expenses", "Hoteling", "Restaurant", "Meeting", "Other"]; // Category options

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (user) {
        const userRef = doc(database, "users", user.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserDetails({
            name: userData.name || "Unnamed User",
            email: userData.email || "No Email Provided",
          });
        } else {
          setUserDetails({
            name: user.displayName || "Unnamed User",
            email: user.email || "No Email Provided",
          });
        }
      }
      setLoading(false);
    };

    const fetchExpenseHistory = async () => {
      if (user) {
        const q = query(collection(database, "expenses"), where("userId", "==", user.uid));
        const querySnapshot = await getDocs(q);
        const expenses = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as ExpenseRecord[];
        setExpenseHistory(expenses);
      }
    };

    fetchUserDetails();
    fetchExpenseHistory();
  }, [user]);

  useEffect(() => {
    calculateTotal();
  }, [expenseItems]);

  const calculateTotal = () => {
    let currentTotal = 0;
    expenseItems.forEach(item => {
      const costValue = parseFloat(item.cost as string);
      if (!isNaN(costValue)) {
        currentTotal += costValue;
      }
    });
    setTotalCost(currentTotal);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);
    setError("");
    setSuccess("");

    try {
      if (!purpose || !category || expenseItems.some(item => !item.date || !item.description || !item.cost)) {
        setError("Please fill in all fields for each expense item, purpose, and category.");
        setIsSubmitting(false);
        return;
      }

      for (const item of expenseItems) {


        const expenseData: ExpenseRecord = {
          userId: user?.uid!,
          date: item.date,
          purpose: purpose,
          description: item.description,
          category: category,
          cost: parseFloat(item.cost as string),
          status: "pending",
          createdAt: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        };
        await addDoc(collection(database, "expenses"), expenseData);
      }

      setSuccess("Expenses submitted successfully!");
      setExpenseItems([{ date: "", description: "", cost: "" }]);
      setPurpose("");
      setCategory("Fuel expenses"); // Reset to default category
    } catch (err: any) {
      setError("Failed to submit expenses. Please try again. " + err.message);
      console.error("Error submitting expenses:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddItem = () => {
    setExpenseItems([...expenseItems, { date: "", description: "", cost: "" }]);
  };

  const handleRemoveItem = (index: number) => {
    const list = [...expenseItems];
    list.splice(index, 1);
    setExpenseItems(list);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number, field: string) => {
    const list = [...expenseItems];
    (list[index] as any)[field] = e.target.value;
    setExpenseItems(list);
  };


  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <DashboardLayout title="Expenses" showBackButton>
      <div className="min-h-screen p-6 pt-20">

        <div className="bg-white shadow-md rounded-2xl p-6 mb-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">User Information</h2>
          <div className="grid gap-4 sm:grid-cols-2">
            <div>
              <label className="block text-gray-600 font-semibold mb-2">Name</label>
              <input
                type="text"
                value={userDetails?.name || ""}
                className="w-full p-2 border border-gray-300 rounded-lg bg-gray-100"
                readOnly
              />
            </div>
            <div>
              <label className="block text-gray-600 font-semibold mb-2">Email</label>
              <input
                type="email"
                value={userDetails?.email || ""}
                className="w-full p-2 border border-gray-300 rounded-lg bg-gray-100"
                readOnly
              />
            </div>
          </div>
        </div>


        <h1 className="text-3xl font-bold text-gray-800 mb-6">Expenses Form</h1>

        <div className="bg-white shadow-md rounded-2xl p-6 mb-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Submit Expense Sheet</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-gray-600 font-semibold mb-2">Purpose of Event</label>
              <input
                type="text"
                value={purpose}
                onChange={(e) => setPurpose(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-lg"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-600 font-semibold mb-2">Category</label>
              <select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-lg"
                required
              >
                {categories.map((cat) => (
                  <option key={cat} value={cat}>{cat}</option>
                ))}
              </select>
            </div>


            <h3 className="text-lg font-semibold text-gray-700 mb-2 mt-4">Expense Details</h3>
            {expenseItems.map((item, index) => (
              <div key={index} className="grid gap-4 sm:grid-cols-3 mb-4 border p-4 rounded-lg">
                <div>
                  <label className="block text-gray-600 font-semibold mb-2">Date</label>
                  <input
                    type="date"
                    value={item.date}
                    onChange={(e) => handleInputChange(e, index, 'date')}
                    className="w-full p-2 border border-gray-300 rounded-lg"
                    required
                  />
                </div>
                <div>
                  <label className="block text-gray-600 font-semibold mb-2">Description</label>
                  <textarea
                    value={item.description}
                    onChange={(e) => handleInputChange(e, index, 'description')}
                    className="w-full p-2 border border-gray-300 rounded-lg"
                    rows={2}
                    required
                  />
                </div>
                <div>
                  <label className="block text-gray-600 font-semibold mb-2">Cost</label>
                  <input
                    type="number"
                    value={item.cost}
                    onChange={(e) => handleInputChange(e, index, 'cost')}
                    className="w-full p-2 border border-gray-300 rounded-lg"
                    required
                  />
                </div>


                {expenseItems.length > 1 && (
                  <div className="sm:col-span-3 flex justify-end">
                    <button
                      type="button"
                      onClick={() => handleRemoveItem(index)}
                      className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-700"
                    >
                      Remove
                    </button>
                  </div>
                )}
              </div>
            ))}

            <div className="mb-4">
              <button
                type="button"
                onClick={handleAddItem}
                className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-700"
              >
                Add Expense Item
              </button>
            </div>

            <div className="mb-4 text-right">
              <span className="text-gray-700 font-semibold text-lg">Total Amount: {totalCost.toFixed(2)}</span>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className={`mt-6 bg-blue-600 text-white px-6 py-3 rounded-lg shadow transition ${
                isSubmitting ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700"
              }`}
            >
              {isSubmitting ? (
                <div className="flex items-center justify-center">
                  <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                      fill="none"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                  Processing...
                </div>
              ) : (
                "Submit Expense Sheet"
              )}
            </button>
            {error && (
              <div className="mt-4 bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-lg">
                {error}
              </div>
            )}
            {success && (
              <div className="mt-4 bg-green-100 border-l-4 border-green-500 text-green-700 p-4 rounded-lg">
                {success}
              </div>
            )}
          </form>
        </div>

        <div className="bg-white shadow-md rounded-2xl p-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">Expense History</h2>

          <table className="min-w-full border border-gray-200 rounded-lg">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-3 px-4 text-left text-sm text-gray-600 font-semibold">Date</th>
                <th className="py-3 px-4 text-left text-sm text-gray-600 font-semibold">Purpose</th>
                <th className="py-3 px-4 text-left text-sm text-gray-600 font-semibold">Description</th>
                <th className="py-3 px-4 text-left text-sm text-gray-600 font-semibold">Category</th>
                <th className="py-3 px-4 text-left text-sm text-gray-600 font-semibold">Cost</th>
                <th className="py-3 px-4 text-left text-sm text-gray-600 font-semibold">Status</th>
              </tr>
            </thead>
            <tbody>
              {expenseHistory.map((expense, index) => (
                <tr
                  key={index}
                  className={`hover:bg-gray-50 ${index % 2 === 0 ? "bg-gray-50" : "bg-white"}`}
                >
                  <td className="py-3 px-4 text-sm text-gray-700">{expense.date}</td>
                  <td className="py-3 px-4 text-sm text-gray-700">{expense.purpose}</td>
                  <td className="py-3 px-4 text-sm text-gray-700">{expense.description}</td>
                  <td className="py-3 px-4 text-sm text-gray-700">{expense.category}</td>
                  <td className="py-3 px-4 text-sm text-gray-700">{expense.cost.toFixed(2)}</td>
                  <td className="py-3 px-4 text-sm text-gray-700">
                    <span
                      className={`px-2 py-1 rounded-full text-sm font-semibold ${
                        expense.status === "approved"
                          ? "bg-green-100 text-green-700"
                          : "bg-yellow-100 text-yellow-700"
                      }`}
                    >
                      {expense.status}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ExpensesForm;