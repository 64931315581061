// src/layouts/dashboardlayout/amdashboard/index.tsx
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaSignOutAlt, FaArrowLeft, FaUserCircle, FaBell } from "react-icons/fa";
import { getAuth, signOut } from "firebase/auth";
import { useAuth } from "../../../Pages/AuthContext";
import { collection, query, where, getDocs, getDoc, doc as firestoreDoc, doc } from "firebase/firestore";
import { database } from "../../../components/Firebase/firebase";
import NotificationBadge from "./notification";

interface AMDashboardLayoutProps {
  title: string;
  children: React.ReactNode;
  showBackButton?: boolean;
}

interface NotificationItem {
  id: string;
  type: 'leave' | 'expense';
  formName: string;
  link: string;
}

// Define interface for UserData
interface UserData {
  [x: string]: string | undefined;
  name?: string;
  // Add other properties of your user document if needed
}

const AMDashboardLayout: React.FC<AMDashboardLayoutProps> = ({
  title,
  children,
  showBackButton = false,
}) => {
  const navigate = useNavigate();
  const { userName, user } = useAuth();
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [notificationDropdownOpen, setNotificationDropdownOpen] = useState(false);
  const [gender, setGender] = useState<string>("");
  const [notifications, setNotifications] = useState<NotificationItem[]>([]);

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Logout failed:", error);
      alert("Failed to log out. Please try again.");
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const fetchNotifications = async () => {
    if (!user) return;

    try {
      const leaveRequestsQuery = query(
        collection(database, "leaveRequests"),
        where("status", "==", "Pending")
      );
      const expenseSheetsQuery = query(
        collection(database, "expenses"),
        where("status", "==", "pending")
      );

      const [leaveRequestsSnapshot, expenseSheetsSnapshot] = await Promise.all([
        getDocs(leaveRequestsQuery),
        getDocs(expenseSheetsQuery),
      ]);

      const leaveNotifications: NotificationItem[] = leaveRequestsSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          type: 'leave',
          formName: data.userName || 'Leave Request',
          link: `/leavelist`,
        };
      });

      const expenseNotifications: NotificationItem[] = await Promise.all(expenseSheetsSnapshot.docs.map(async doc => {
        const data = doc.data();
        let employeeName = 'Expense Sheet';
        if (data.userId) {
          try {
            const userDocRef = firestoreDoc(database, "users", data.userId);
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
              const userData = userDoc.data() as UserData;
              employeeName = userData.name || 'Expense Sheet';
            }
          } catch (error) {
            console.error("Error fetching user for expense notification:", error);
          }
        }
        return {
          id: doc.id,
          type: 'expense',
          formName: employeeName,
          link: `/expenselistpage`,
        };
      }));

      setNotifications([...leaveNotifications, ...expenseNotifications]);

    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };


  useEffect(() => {
    const fetchUserGender = async () => {
      if (user) {
        try {
          const userDocRef = doc(database, "users", user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data() as UserData;
            setGender(userData.gender || "");
          }
        } catch (error) {
          console.error("Error fetching user gender:", error);
        }
      }
    };

    fetchUserGender();
    fetchNotifications();
  }, [user]);

  return (
    <body className="font-inter bg-dashboard-bg text-text-grey flex justify-center items-center min-h-screen">
    <div className="dashboard-container bg-container-bg rounded-2xl shadow-lg overflow-hidden w-full ">

      {/* Header */}
      <div className="dashboard-header px-6 py-5 flex justify-between items-center">
        <div className="container mx-auto px-6 py-4 flex justify-between items-center">
          {/* Logo */}
          <div className="font-bold text-xl text-gray-800">
            <img src="/images/logo/cprlogo.png" alt="Logo" className="h-8  inline-block" />
          </div>

          {/* Actions (Back Button, Notifications, Profile) */}
          <div className="flex items-center space-x-4">
            {/* Back Button */}
            {showBackButton && (
              <button
                onClick={handleBack}
                className="flex items-center bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-colors"
              >
                <FaArrowLeft className="mr-2" />
                Back
              </button>
            )}

            {/* Notifications */}
            <div className="relative">
              <button
                onClick={() => {
                  setNotificationDropdownOpen(!notificationDropdownOpen);
                  setProfileDropdownOpen(false);
                }}
                className="p-2 rounded-full hover:bg-gray-100 transition"
              >
                <FaBell className="text-xl" />
                {notifications.length > 0 && (
                   <NotificationBadge count={notifications.length} />
                )}
              </button>

              {notificationDropdownOpen && (
                <div className="absolute right-0 mt-2 w-64 bg-white rounded-md shadow-lg border border-gray-200 z-10">
                  <ul className="py-2">
                    {notifications.length > 0 ? (
                      notifications.map((notification, index) => (
                        <li key={notification.id}>
                          <Link
                            to={notification.link}
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            onClick={() => setNotificationDropdownOpen(false)}
                          >
                            New {notification.type} form <span className="font-semibold">{notification.formName}</span> waiting for approval.
                          </Link>
                        </li>
                      ))
                    ) : (
                      <li className="px-4 py-2 text-sm text-gray-500">
                        No new notifications.
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>

            {/* Profile Dropdown */}
            <div className="relative">
              <button
                onClick={() => {
                  setProfileDropdownOpen(!profileDropdownOpen);
                  setNotificationDropdownOpen(false);
                }}
                className="flex items-center space-x-2 bg-gray-100 text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-200 transition-colors"
              >
                <img
                  src={gender === "male"
                    ? "/images/avatar/avatarmale.webp"
                    : "/images/avatar/avatarfemale.webp"}
                  alt="Profile"
                  className="h-8 w-8 rounded-full object-cover"
                />

              </button>

              {/* Dropdown Menu */}
              {profileDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-md border border-gray-200">
                  <ul className="py-2">
                    <li>
                    <span className="font-medium block w-full text-left px-4 py-2 ">{userName || "User"}</span>
                    </li>
                    <li>
                      <button
                        onClick={() => { setProfileDropdownOpen(false); navigate("/myprofile")}}
                        className="block w-full text-left px-4 py-2 hover:bg-gray-100 transition-colors"
                      >
                        <FaUserCircle className="inline-block mr-2" />
                        Profile
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={handleLogout}
                        className="block w-full text-left px-4 py-2 hover:bg-gray-100 transition-colors"
                      >
                        <FaSignOutAlt className="inline-block mr-2" />
                        Logout
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}

      <div className="container mx-auto px-6 pt-5">{children}</div>


    </div>
    </body>
  );
};

export default AMDashboardLayout;