import React, { useState, useEffect } from "react";
import { database } from "../../components/Firebase/firebase";
import {
    collection,
    addDoc,
    serverTimestamp,
    onSnapshot,
    query,
    orderBy,
    doc,
    deleteDoc,
    updateDoc,
} from "firebase/firestore";
import { formatDistanceToNow } from 'date-fns';
import { FaEdit, FaTrash } from 'react-icons/fa';

interface AdminNotification {
    id: string;
    title: string;
    message: string;
    createdAt: { toDate: () => Date } | null;
}

const AdminNotificationCreator: React.FC = () => {
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [isCreating, setIsCreating] = useState(false);
    const [creationSuccess, setCreationSuccess] = useState<boolean | null>(null);
    const [notifications, setNotifications] = useState<AdminNotification[]>([]);
    const [loadingNotifications, setLoadingNotifications] = useState(true);
    const [editingNotificationId, setEditingNotificationId] = useState<string | null>(null);
    const [editedTitle, setEditedTitle] = useState("");
    const [editedMessage, setEditedMessage] = useState("");
    const [isProcessingAction, setIsProcessingAction] = useState(false);
    const [processingActionType, setProcessingActionType] = useState<'edit' | 'delete' | null>(null);

    useEffect(() => {
        const q = query(collection(database, "adminNotifications"), orderBy("createdAt", "desc"));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const notificationList: AdminNotification[] = [];
            snapshot.forEach((doc) => {
                notificationList.push({
                    id: doc.id,
                    ...(doc.data() as Omit<AdminNotification, 'id'>),
                    createdAt: doc.data().createdAt || null,
                });
            });
            setNotifications(notificationList);
            setLoadingNotifications(false);
        }, (error) => {
            console.error("Error fetching admin notifications:", error);
            setLoadingNotifications(false);
        });
        return () => unsubscribe();
    }, []);


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!title.trim() || !message.trim()) {
            alert("Please enter both title and message.");
            return;
        }

        setIsCreating(true);
        setCreationSuccess(null);

        try {
            await addDoc(collection(database, "adminNotifications"), {
                title: title,
                message: message,
                createdAt: serverTimestamp(),
            });
            setCreationSuccess(true);
            setTitle("");
            setMessage("");
        } catch (error) {
            console.error("Error creating admin notification:", error);
            setCreationSuccess(false);
        } finally {
            setIsCreating(false);
            setTimeout(() => setCreationSuccess(null), 3000);
        }
    };

    const handleEditNotification = (notification: AdminNotification) => {
        setEditingNotificationId(notification.id);
        setEditedTitle(notification.title);
        setEditedMessage(notification.message);
    };

    const handleCancelEdit = () => {
        setEditingNotificationId(null);
        setEditedTitle("");
        setEditedMessage("");
    };

    const handleUpdateNotification = async (id: string) => {
        if (!editedTitle.trim() || !editedMessage.trim()) {
            alert("Please enter both title and message.");
            return;
        }
        setIsProcessingAction(true);
        setProcessingActionType('edit');
        try {
            await updateDoc(doc(database, "adminNotifications", id), {
                title: editedTitle,
                message: editedMessage,
            });
            setEditingNotificationId(null);
            setEditedTitle("");
            setEditedMessage("");
        } catch (error) {
            console.error("Error updating notification:", error);
            alert("Failed to update notification.");
        } finally {
            setIsProcessingAction(false);
            setProcessingActionType(null);
        }
    };

    const handleDeleteNotification = async (id: string) => {
        if (window.confirm("Are you sure you want to delete this notification?")) {
            setIsProcessingAction(true);
            setProcessingActionType('delete');
            try {
                await deleteDoc(doc(database, "adminNotifications", id));
            } catch (error) {
                console.error("Error deleting notification:", error);
                alert("Failed to delete notification.");
            } finally {
                setIsProcessingAction(false);
                setProcessingActionType(null);
            }
        }
    };


    if (loadingNotifications) {
        return <p>Loading notifications...</p>;
    }


    return (
    
        <div className="p-6">
            <h2 className="text-xl font-semibold mb-4 text-gray-800">Admin Notification Management</h2>

            {/* Create Notification Form */}
            <div className="mb-8 p-6 bg-white shadow-md rounded-md border border-gray-200">
                <h3 className="text-lg font-semibold mb-4 text-gray-700">Create New Notification</h3>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title:</label>
                        <input
                            type="text"
                            id="title"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message:</label>
                        <textarea
                            id="message"
                            rows={4}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <button
                            type="submit"
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            disabled={isCreating}
                        >
                            {isCreating ? (
                                <span className="inline-flex items-center">
                                    <svg className="animate-spin -ml-1 mr-2 h-5 w-5 text-white" viewBox="0 0 24 24" fill="none">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Creating...
                                </span>
                            ) : "Create Notification"}
                        </button>
                    </div>
                    {creationSuccess === true && (
                        <p className="text-green-500">Notification created successfully!</p>
                    )}
                    {creationSuccess === false && (
                        <p className="text-red-500">Failed to create notification. Please try again.</p>
                    )}
                </form>
            </div>

            {/* Display and Manage Existing Notifications */}
            <div className="bg-white shadow-md rounded-md overflow-x-auto">
                <table className="min-w-full table-auto border-collapse">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="p-3 text-left">Title</th>
                            <th className="p-3 text-left">Message</th>
                            <th className="p-3 text-left">Created At</th>
                            <th className="p-3 text-left">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {notifications.map((notification) => (
                            <tr key={notification.id} className="border-b hover:bg-gray-50">
                                <td className="p-3">
                                    {editingNotificationId === notification.id ? (
                                        <input
                                            type="text"
                                            value={editedTitle}
                                            onChange={(e) => setEditedTitle(e.target.value)}
                                            className="w-full border-gray-300 rounded-md"
                                        />
                                    ) : (
                                        notification.title
                                    )}
                                </td>
                                <td className="p-3">
                                    {editingNotificationId === notification.id ? (
                                        <textarea
                                            value={editedMessage}
                                            onChange={(e) => setEditedMessage(e.target.value)}
                                            rows={2}
                                            className="w-full border-gray-300 rounded-md"
                                        />
                                    ) : (
                                        notification.message
                                    )}
                                </td>
                                <td className="p-3 text-sm text-gray-500">
                                    {notification.createdAt && formatDistanceToNow(notification.createdAt.toDate(), { addSuffix: true })}
                                </td>
                                <td className="p-3 flex space-x-2">
                                    {editingNotificationId === notification.id ? (
                                        <>
                                            <button
                                                onClick={() => handleUpdateNotification(notification.id)}
                                                className={`p-2 bg-green-500 hover:bg-green-700 text-white rounded ${isProcessingAction && processingActionType === 'edit' ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                disabled={isProcessingAction && processingActionType === 'edit'}
                                            >
                                                {isProcessingAction && processingActionType === 'edit' ? 'Updating...' : 'Save'}
                                            </button>
                                            <button
                                                onClick={handleCancelEdit}
                                                className="p-2 bg-gray-300 hover:bg-gray-400 text-gray-700 rounded"
                                                disabled={isProcessingAction && processingActionType === 'edit'}
                                            >
                                                Cancel
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button
                                                onClick={() => handleEditNotification(notification)}
                                                className="p-2 bg-blue-500 hover:bg-blue-700 text-white rounded"
                                                disabled={isProcessingAction}
                                            >
                                                <FaEdit className="h-4 w-4"/>
                                            </button>
                                            <button
                                                onClick={() => handleDeleteNotification(notification.id)}
                                                className={`p-2 bg-red-500 hover:bg-red-700 text-white rounded ${isProcessingAction && processingActionType === 'delete' ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                disabled={isProcessingAction && processingActionType === 'delete'}
                                            >
                                                <FaTrash className="h-4 w-4"/>
                                            </button>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    
    );
};

export default AdminNotificationCreator;