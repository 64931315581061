import React, { useState, useEffect } from "react";
import { useAuth } from "../../Pages/AuthContext";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import firebaseApp from "../Firebase/firebase";
import DashboardLayout from "../../layouts/dashboardlayout";

const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

const MyProfile: React.FC = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [role, setRole] = useState("");
    const [gender, setGender] = useState("");
    const [resetEmailSent, setResetEmailSent] = useState(false);
    const [resetError, setResetError] = useState("");
    const [theme, setTheme] = useState("Dark"); // Default Theme

    useEffect(() => {
        const fetchUserProfile = async () => {
            if (user) {
                const userDoc = await getDoc(doc(db, "users", user.uid));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setName(userData.name || "");
                    setEmail(userData.email || "");
                    setContactNumber(userData.contactNumber || "");
                    setRole(userData.role || "");
                    setGender(userData.gender || "");
                    setTheme(userData.theme || "Dark");
                }
            }
        };
        fetchUserProfile();
    }, [user]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (user) {
            await updateDoc(doc(db, "users", user.uid), {
                name,
                email,
                contactNumber,
                gender,
                theme
            });
            alert("Profile updated successfully!");
        }
    };

    const handlePasswordReset = async () => {
        if (!user?.email) {
            setResetError("No email address found for this user.");
            return;
        }

        try {
            await sendPasswordResetEmail(auth, user.email);
            setResetEmailSent(true);
            setResetError("");
            alert("Password reset email sent successfully!");
        } catch (error: any) {
            setResetError(error.message);
            console.error("Error sending password reset email:", error);
        }
    };


    const themes = [
        { value: "Dark", label: "Dark" },
        { value: "Light", label: "Light" },
        { value: "System", label: "System" },
    ];


    return (
        <DashboardLayout title="User Profile" showBackButton>
            <div className="w-full pt-6 p-6">
                <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-8">
                    <div className="flex items-center justify-between gap-4 pb-6 border-b border-solid border-gray-200">
                        <div className="block">
                            <h3 className="text-lg font-semibold text-black mb-2">General Details</h3>
                            <p className="text-xs font-normal text-gray-500">Comprehensive Overview of Core
                                Configuration and General Settings</p>
                        </div>
                        <div className="flex items-center gap-4">
                            <button className="rounded-md py-2 px-3 border border-gray-300 text-xs font-medium text-gray-900 bg-white transition-all duration-300 hover:bg-gray-100">Cancel</button>
                            <button
                                onClick={handleSubmit}
                                className="rounded-md py-2 px-3 bg-gray-900 text-white text-xs font-medium transition-all duration-300 hover:bg-gray-800">
                                Save
                            </button>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-5 pt-6">
                        <div className="col-span-12 lg:col-span-7">
                            <div className="p-4 border border-gray-200 rounded-2xl ">
                                <h5 className="text-base font-semibold text-gray-900 pb-4 border-b border-gray-200">
                                    Basic Information</h5>
                                <form action="#" className="flex flex-col gap-4 pt-4">
                                    <div className="mb-6 text-center">
                                        <img
                                            src={gender === "male"
                                                ? "/images/avatar/avatarmale.webp"
                                                : "/images/avatar/avatarfemale.webp"}
                                            alt="Profile"
                                            className="w-24 h-24 rounded-full mx-auto border-2 border-gray-200 shadow-md"
                                        />
                                    </div>
                                    <div className="relative">
                                        <label className="flex  items-center mb-2 text-gray-600 text-xs font-medium">Name
                                            <svg width="7" height="7" className="ml-1" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.11222 6.04545L3.20668 3.94744L1.43679 5.08594L0.894886 4.14134L2.77415 3.18182L0.894886 2.2223L1.43679 1.2777L3.20668 2.41619L3.11222 0.318182H4.19105L4.09659 2.41619L5.86648 1.2777L6.40838 2.2223L4.52912 3.18182L6.40838 4.14134L5.86648 5.08594L4.09659 3.94744L4.19105 6.04545H3.11222Z" fill="#EF4444"></path>
                                            </svg>
                                        </label>
                                        <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} className="block w-full px-3 py-1.5 pr-8 text-sm font-normal shadow-xs text-gray-900 bg-transparent border border-gray-300 rounded-lg placeholder-gray-400 focus:outline-none leading-relaxed" placeholder="Your Name"  />
                                    </div>
                                    <div className="relative">
                                        <label className="flex  items-center mb-2 text-gray-600 text-xs font-medium">Email
                                            Address
                                            <svg width="7" height="7" className="ml-1" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.11222 6.04545L3.20668 3.94744L1.43679 5.08594L0.894886 4.14134L2.77415 3.18182L0.894886 2.2223L1.43679 1.2777L3.20668 2.41619L3.11222 0.318182H4.19105L4.09659 2.41619L5.86648 1.2777L6.40838 2.2223L4.52912 3.18182L6.40838 4.14134L5.86648 5.08594L4.09659 3.94744L4.19105 6.04545H3.11222Z" fill="#EF4444"></path>
                                            </svg>
                                        </label>
                                        <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="block w-full px-3 py-1.5 pr-8 text-sm font-normal shadow-xs text-gray-900 bg-transparent border border-gray-300 rounded-lg placeholder-gray-400 focus:outline-none leading-relaxed" placeholder="name@pagedone.com"  />
                                        <svg className="absolute bottom-3 right-2.5" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                            <path d="M4.76635 6.62811L5.1199 6.27456L5.1199 6.27456L4.76635 6.62811ZM4.35355 5.50821C4.15829 5.31295 3.84171 5.31295 3.64645 5.50821C3.45118 5.70347 3.45118 6.02005 3.64645 6.21532L4.35355 5.50821ZM6.18056 6.62811L5.82701 6.27456L5.82701 6.27456L6.18056 6.62811ZM8.68877 4.82701C8.88403 4.63174 8.88403 4.31516 8.68877 4.1199C8.49351 3.92464 8.17693 3.92464 7.98166 4.1199L8.68877 4.82701ZM10 6C10 8.20914 8.20914 10 6 10V11C8.76142 11 11 8.76142 11 6H10ZM6 10C3.79086 10 2 8.20914 2 6H1C1 8.76142 3.23858 11 6 11V10ZM2 6C2 3.79086 3.79086 2 6 2V1C3.23858 1 1 3.23858 1 6H2ZM6 2C8.20914 2 10 3.79086 10 6H11C11 3.23858 8.76142 1 6 1V2ZM5.1199 6.27456L4.35355 5.50821L3.64645 6.21532L4.41279 6.98166L5.1199 6.27456ZM4.41279 6.98166C4.56947 7.13833 4.72179 7.29216 4.86394 7.40062C5.01764 7.51789 5.21662 7.62811 5.47345 7.62811V6.62811C5.52318 6.62811 5.53527 6.655 5.47053 6.6056C5.39424 6.5474 5.29656 6.45122 5.1199 6.27456L4.41279 6.98166ZM5.82701 6.27456C5.65035 6.45122 5.55267 6.5474 5.47638 6.6056C5.41164 6.655 5.42373 6.62811 5.47345 6.62811V7.62811C5.73029 7.62811 5.92926 7.51789 6.08296 7.40062C6.22512 7.29216 6.37744 7.13833 6.53411 6.98166L5.82701 6.27456ZM7.98166 4.1199L5.82701 6.27456L6.53411 6.98166L8.68877 4.82701L7.98166 4.1199Z" fill="#4F46E5"></path>
                                        </svg>
                                    </div>
                                    <div className="relative">
                                        <label className="flex  items-center mb-2 text-gray-600 text-xs font-medium">Contact Number
                                            <svg width="7" height="7" className="ml-1" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.11222 6.04545L3.20668 3.94744L1.43679 5.08594L0.894886 4.14134L2.77415 3.18182L0.894886 2.2223L1.43679 1.2777L3.20668 2.41619L3.11222 0.318182H4.19105L4.09659 2.41619L5.86648 1.2777L6.40838 2.2223L4.52912 3.18182L6.40838 4.14134L5.86648 5.08594L4.09659 3.94744L4.19105 6.04545H3.11222Z" fill="#EF4444"></path>
                                            </svg>
                                        </label>
                                        <input type="text" id="contactNumber" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} className="block w-full px-3 py-1.5 pr-8 text-sm font-normal shadow-xs text-gray-900 bg-transparent border border-gray-300 rounded-lg placeholder-gray-400 focus:outline-none leading-relaxed" placeholder="Contact Number"  />
                                    </div>
                                    <div className="relative">
                                        <label className="flex  items-center mb-2 text-gray-600 text-xs font-medium">Role
                                            <svg width="7" height="7" className="ml-1" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.11222 6.04545L3.20668 3.94744L1.43679 5.08594L0.894886 4.14134L2.77415 3.18182L0.894886 2.2223L1.43679 1.2777L3.20668 2.41619L3.11222 0.318182H4.19105L4.09659 2.41619L5.86648 1.2777L6.40838 2.2223L4.52912 3.18182L6.40838 4.14134L5.86648 5.08594L4.09659 3.94744L4.19105 6.04545H3.11222Z" fill="#EF4444"></path>
                                            </svg>
                                        </label>
                                        <input type="text" id="role" value={role} readOnly className="block w-full px-3 py-1.5 pr-8 text-sm font-normal shadow-xs text-gray-500 bg-gray-100 border border-gray-300 rounded-lg placeholder-gray-400 focus:outline-none leading-relaxed" placeholder="Role"  />
                                    </div>
                                    <div className="relative">
                                        <label htmlFor="gender" className=" mb-2 text-xs font-medium text-gray-600 w-full flex items-center">Gender
                                            <svg width="7" height="7" className="ml-1" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.11222 6.04545L3.20668 3.94744L1.43679 5.08594L0.894886 4.14134L2.77415 3.18182L0.894886 2.2223L1.43679 1.2777L3.20668 2.41619L3.11222 0.318182H4.19105L4.09659 2.41619L5.86648 1.2777L6.40838 2.2223L4.52912 3.18182L6.40838 4.14134L5.86648 5.08594L4.09659 3.94744L4.19105 6.04545H3.11222Z" fill="#EF4444"></path>
                                            </svg></label>
                                        <select
                                            id="gender"
                                            value={gender}
                                            onChange={(e) => setGender(e.target.value)}
                                            className="border border-gray-300 text-gray-900 text-xs font-normal rounded-lg block w-full py-2 px-3 focus:outline-none"
                                            required
                                        >
                                            <option value="">Select Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-span-12 lg:col-span-5">
                            <div className="p-4 border border-gray-200 rounded-2xl ">
                                <h5 className="text-base font-semibold text-gray-900 pb-4 border-b border-gray-200">
                                    Choose your theme</h5>

                                <div className="block w-full pt-4">
                                    <label   className="block mb-2 text-xs font-medium text-gray-600 w-full">Change the
                                        colors that appear in dashboard</label>
                                    <select
                                        id="theme"
                                        className=" border border-gray-300 text-gray-600 text-xs font-normal rounded-lg block w-full py-1.5 px-3 pr-8 focus:outline-none"
                                        value={theme}
                                        onChange={(e) => setTheme(e.target.value)}
                                    >
                                        {themes.map((option) => (
                                            <option key={option.value} value={option.value}>{option.label}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="p-4 border border-gray-200 rounded-2xl mt-5 ">
                                <h5 className="text-base font-semibold text-gray-900 pb-4 border-b border-gray-200">
                                    Password Reset</h5>
                                <div className="block w-full pt-4">
                                    <button
                                        type="button"
                                        onClick={handlePasswordReset}
                                        className="w-full bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-colors text-xs font-medium"
                                    >
                                        Send Password Reset Email
                                    </button>
                                    {resetEmailSent && (
                                        <p className="mt-2 text-green-600 text-xs font-medium">Password reset email sent successfully!</p>
                                    )}
                                    {resetError && (
                                        <p className="mt-2 text-red-600 text-xs font-medium">{resetError}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
};

export default MyProfile;