// ExpenseListPage.tsx (Updated - File upload removed)
import React, { useState, useEffect } from "react";
import { useAuth } from "./AuthContext";
import DashboardLayout from "../layouts/dashboardlayout";
import {
  collection,
  query,
  getDocs,
  updateDoc,
  doc,
  getDoc,
  where,
} from "firebase/firestore";
import { database } from "../components/Firebase/firebase";
import { format, parseISO, getMonth, getYear } from "date-fns";

interface ExpenseRecord {
  id?: string;
  userId: string;
  date: string;
  purpose: string;
  description: string;
  category: string;
  cost: number;
  status: "pending" | "approved" | "rejected";
  createdAt: string;
}

interface UserDetails {
  name: string;
  email: string;
}

const ExpenseListPage: React.FC = () => {
  const { user } = useAuth();
  const [expenseHistory, setExpenseHistory] = useState<ExpenseRecord[]>([]);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<UserDetails[]>([]);
  const [selectedUserFilter, setSelectedUserFilter] = useState<string>("all");
  const [selectedMonthFilter, setSelectedMonthFilter] = useState<string>("all");
  const [filteredExpenses, setFilteredExpenses] = useState<ExpenseRecord[]>([]);

  useEffect(() => {
    const fetchExpenseHistory = async () => {
      if (user) {
        const q = query(collection(database, "expenses"));
        const querySnapshot = await getDocs(q);
        const expenses = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as ExpenseRecord[];
        setExpenseHistory(expenses);
        setFilteredExpenses(expenses); // Initially show all expenses
      }
      setLoading(false);
    };

    const fetchUsers = async () => {
      const usersQuery = query(collection(database, "users"));
      const usersSnapshot = await getDocs(usersQuery);
      const usersList = usersSnapshot.docs.map((doc) => doc.data()) as UserDetails[];
      setUsers(usersList);
    };

    fetchExpenseHistory();
    fetchUsers();
  }, [user]);

  useEffect(() => {
    applyFilters();
  }, [expenseHistory, selectedUserFilter, selectedMonthFilter]);

  const applyFilters = () => {
    let filtered = [...expenseHistory];

    if (selectedUserFilter !== "all") {
      filtered = filtered.filter((expense) => expense.userId === selectedUserFilter);
    }

    if (selectedMonthFilter !== "all") {
      const [year, month] = selectedMonthFilter.split("-");
      filtered = filtered.filter(expense => {
        const expenseDate = parseISO(expense.date);
        return getMonth(expenseDate) + 1 === parseInt(month) && getYear(expenseDate) === parseInt(year);
      });
    }
    setFilteredExpenses(filtered);
  };


  const handleUserFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedUserFilter(e.target.value);
  };

  const handleMonthFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMonthFilter(e.target.value);
  };


  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <DashboardLayout title="Expense List" showBackButton>
      <div className="min-h-screen   p-6 pt-20">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">
          Team Expense Requests
        </h1>

        {/* Filters */}
        <div className="mb-6 flex gap-4 items-center">
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">Filter by User:</label>
            <select
              value={selectedUserFilter}
              onChange={handleUserFilterChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="all">All Users</option>
              {users.map((user) => (
                <option key={user.email} value={user.email}>
                  {user.name} ({user.email})
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">Filter by Month:</label>
            <input
              type="month"
              value={selectedMonthFilter === 'all' ? '' : selectedMonthFilter}
              onChange={handleMonthFilterChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        </div>


        {/* New Expense Notifications */}
        {filteredExpenses
          .filter((expense) => expense.status === "pending")
          .map((expense, index) => (
            <ExpenseNotification
              key={index}
              expense={expense}
              setExpenseHistory={setExpenseHistory}
              expenseHistory={expenseHistory}
            />
          ))}

        {/* Expense History */}
        <div className="bg-white shadow-md rounded-2xl p-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">
            Expense History
          </h2>
          <table className="min-w-full border border-gray-200 rounded-lg">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-3 px-4 text-left text-sm text-gray-600 font-semibold">
                  User
                </th>
                <th className="py-3 px-4 text-left text-sm text-gray-600 font-semibold">
                  Date
                </th>
                <th className="py-3 px-4 text-left text-sm text-gray-600 font-semibold">
                  Purpose
                </th>
                <th className="py-3 px-4 text-left text-sm text-gray-600 font-semibold">
                  Description
                </th>
                <th className="py-3 px-4 text-left text-sm text-gray-600 font-semibold">
                  Category
                </th>
                <th className="py-3 px-4 text-left text-sm text-gray-600 font-semibold">
                  Cost
                </th>
                <th className="py-3 px-4 text-left text-sm text-gray-600 font-semibold">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredExpenses.map((expense, index) => (
                <ExpenseRow key={index} expense={expense} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </DashboardLayout>
  );
};

interface ExpenseNotificationProps {
  expense: ExpenseRecord;
  expenseHistory: ExpenseRecord[];
  setExpenseHistory: React.Dispatch<React.SetStateAction<ExpenseRecord[]>>;
}

const ExpenseNotification: React.FC<ExpenseNotificationProps> = ({
  expense,
  setExpenseHistory,
  expenseHistory,
}) => {
  const { user } = useAuth();
  const [userDetails, setUserDetails] = useState<UserDetails>({
    name: "",
    email: "",
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [processingExpenseId, setProcessingExpenseId] = useState<string | null>(null);


  useEffect(() => {
    const fetchUserDetails = async () => {
      if (expense.userId) {
        const userDoc = await getDoc(doc(database, "users", expense.userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserDetails({
            name: userData.name,
            email: userData.email,
          });
        }
      }
    };

    fetchUserDetails();
  }, [expense.userId]);

  const handleStatusChange = async (newStatus: "pending" | "approved" | "rejected") => {
    if (user && expense.id && !isProcessing) {
      setIsProcessing(true);
      setProcessingExpenseId(expense.id);
      const expenseRef = doc(database, "expenses", expense.id);
      try {
        await updateDoc(expenseRef, { status: newStatus });
        const updatedExpenseHistory = expenseHistory.map((exp) =>
          exp.id === expense.id ? { ...exp, status: newStatus } : exp
        );
        setExpenseHistory(updatedExpenseHistory);
      } catch (error) {
        console.error("Error updating expense status:", error);
      } finally {
        setIsProcessing(false);
        setProcessingExpenseId(null);
      }
    }
  };


  return (
    <div className="bg-white shadow-md rounded-2xl p-6 mb-4 border border-yellow-300">
      <h3 className="text-lg font-semibold text-gray-700 mb-2">
        New Expense Request
      </h3>
      <p className="text-gray-600 mb-2">
        <strong>User:</strong> {userDetails?.name} ({userDetails?.email})
      </p>
      <p className="text-gray-600 mb-2">
        <strong>Date:</strong> {format(parseISO(expense.date), 'yyyy-MM-dd')}
      </p>
      <p className="text-gray-600 mb-2">
        <strong>Purpose:</strong> {expense.purpose}
      </p>
      <p className="text-gray-600 mb-2">
        <strong>Description:</strong> {expense.description}
      </p>
      <p className="text-gray-600 mb-2">
        <strong>Category:</strong> {expense.category}
      </p>
       <p className="text-gray-600 mb-2">
        <strong>Cost:</strong>  {expense.cost.toFixed(2)}
      </p>

      <div className="mt-2 flex items-center justify-end">
            <button
                onClick={() => handleStatusChange("approved")}
                className={`bg-green-500 text-white px-2 py-1 rounded text-xs hover:bg-green-700 mr-1 ${isProcessing && processingExpenseId === expense.id ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={isProcessing && processingExpenseId === expense.id}
            >
                {isProcessing && processingExpenseId === expense.id ? (
                    <span className="inline-flex items-center">
                        <svg className="animate-spin h-4 w-4 mr-1" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83"></path></svg>
                        Approving...
                    </span>
                ) : 'Approve'}
            </button>
            <button
                onClick={() => handleStatusChange("rejected")}
                className={`bg-red-500 text-white px-2 py-1 rounded text-xs hover:bg-red-700 ${isProcessing && processingExpenseId === expense.id ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={isProcessing && processingExpenseId === expense.id}
            >
                {isProcessing && processingExpenseId === expense.id ? (
                    <span className="inline-flex items-center">
                        <svg className="animate-spin h-4 w-4 mr-1" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M12 2v4M12 18v4M4.93 4.93l2.83 2.83M16.24 16.24l2.83 2.83M2 12h4M18 12h4M4.93 19.07l2.83-2.83M16.24 7.76l2.83-2.83"></path></svg>
                        Rejecting...
                    </span>
                ) : 'Reject'}
            </button>
        </div>
      </div>
    );
  };

interface ExpenseRowProps {
  expense: ExpenseRecord;
}

const ExpenseRow: React.FC<ExpenseRowProps> = ({ expense }) => {
  const [userDetails, setUserDetails] = useState<UserDetails>({
    name: "",
    email: "",
  });
  useEffect(() => {
    const fetchUserDetails = async () => {
      if (expense.userId) {
        const userDoc = await getDoc(doc(database, "users", expense.userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserDetails({ name: userData.name, email: userData.email });
        }
      }
    };

    fetchUserDetails();
  }, [expense.userId]);
  return (
    <tr
      className={`hover:bg-gray-50 ${
        expense.status === "pending"
          ? "bg-yellow-50"
          : expense.status === "approved"
          ? "bg-green-50" 
          : expense.status === "rejected"
          ? "bg-red-50"
          : "bg-white"
      }`}
    >
      <td className="py-3 px-4 text-sm text-gray-700">{userDetails?.name}</td>
      <td className="py-3 px-4 text-sm text-gray-700">{expense.date}</td>
      <td className="py-3 px-4 text-sm text-gray-700">{expense.purpose}</td>
      <td className="py-3 px-4 text-sm text-gray-700">{expense.description}</td>
      <td className="py-3 px-4 text-sm text-gray-700">{expense.category}</td>
      <td className="py-3 px-4 text-sm text-gray-700">{expense.cost.toFixed(2)}</td>
      <td className="py-3 px-4 text-sm text-gray-700">
        <span
          className={`px-2 py-1 rounded-full text-sm font-semibold ${
            expense.status === "approved"
              ? "bg-green-100 text-green-700"
              : expense.status === "rejected"
              ? "bg-red-100 text-red-700"
              : "bg-yellow-100 text-yellow-700"
          }`}
        >
          {expense.status}
        </span>
      </td>
    </tr>
  );
};

export default ExpenseListPage;