import React from "react";
import { useAuth } from "./AuthContext"; // Adjust the path
import { FaUser, FaMoneyBill, FaCalendarAlt, FaCog, FaSignOutAlt, FaUserCircle } from "react-icons/fa"; // Import icons from react-icons
import { useNavigate } from "react-router-dom"; // For navigation

const AdminDashboard: React.FC = () => {
  const { userName, logout } = useAuth(); // Destructure `userName` and `logout`
  const navigate = useNavigate(); // Hook for navigation

  // Handle option clicks
  const handleOptionClick = (option: string) => {
    switch (option) {
      case "Attendance":
        navigate("/userattendance"); // Redirect to Users Management page
        console.log("Navigate to Attendance");
        break;
      case "Salaries":
        console.log("Navigate to Salaries");
        break;
      case "Users":
        navigate("/usersmanagement"); // Redirect to Users Management page
        break;
      case "Settings":
        navigate("/nofificationmanageer"); 
        console.log("Navigate to Settings");
        break;
      case "MyProfile":
        navigate("/myprofile"); // Redirect to My Profile page
        break;
      default:
        break;
    }
  };

  // Handle logout
  const handleLogout = () => {
    logout(); // Call the logout function from AuthContext
    navigate("/login"); // Redirect to the login page
  };

  return (
    <div className="min-h-screen bg-gray-50 font-sans">
      {/* Upper Div with Logo */}
      <div className="fixed top-0 left-0 w-full bg-white shadow-sm z-10">
        <div className="container mx-auto px-6 py-4 flex justify-center">
          <img src="/images/logo/cprlogo.png" alt="Company Logo" className="h-12" />
        </div>
      </div>

      {/* Second Row with Admin Dashboard Title and Logout Button */}
      <div className="fixed top-16 left-0 w-full bg-white shadow-sm z-10">
        <div className="container mx-auto px-6 py-4 flex justify-between items-center">
          <h1 className="text-2xl font-light text-gray-800">Admin Dashboard</h1>
          <button
            onClick={handleLogout}
            className="flex items-center bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-colors"
          >
            <FaSignOutAlt className="mr-2" />
            Logout
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-6 pt-24 pb-8">
        {/* Welcome Message */}
        <p className="text-lg text-gray-600 mb-8">Welcome, {userName}!</p>

        {/* Grid layout for options */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* My Profile Card */}
          <div
            className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow cursor-pointer transform hover:scale-105"
            onClick={() => handleOptionClick("MyProfile")}
          >
            <div className="text-center">
              <FaUserCircle className="text-pink-500 text-5xl mx-auto mb-6" />
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">My Profile</h2>
              <p className="text-gray-600">View and update your profile.</p>
            </div>
          </div>
          
          {/* Attendance Card */}
          <div
            className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow cursor-pointer transform hover:scale-105"
            onClick={() => handleOptionClick("Attendance")}
          >
            <div className="text-center">
              <FaCalendarAlt className="text-blue-500 text-5xl mx-auto mb-6" />
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">Attendance</h2>
              <p className="text-gray-600">Manage employee attendance records.</p>
            </div>
          </div>

          {/* Salaries Card */}
          <div
            className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow cursor-pointer transform hover:scale-105"
            onClick={() => handleOptionClick("Salaries")}
          >
            <div className="text-center">
              <FaMoneyBill className="text-green-500 text-5xl mx-auto mb-6" />
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">Salaries</h2>
              <p className="text-gray-600">View and manage employee salaries.</p>
            </div>
          </div>

          {/* Users Card */}
          <div
            className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow cursor-pointer transform hover:scale-105"
            onClick={() => handleOptionClick("Users")}
          >
            <div className="text-center">
              <FaUser className="text-purple-500 text-5xl mx-auto mb-6" />
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">Users</h2>
              <p className="text-gray-600">Manage user accounts and permissions.</p>
            </div>
          </div>

          {/* Settings Card */}
          <div
            className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow cursor-pointer transform hover:scale-105"
            onClick={() => handleOptionClick("Settings")}
          >
            <div className="text-center">
              <FaCog className="text-yellow-500 text-5xl mx-auto mb-6" />
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">Notifications</h2>
              <p className="text-gray-600">Notification for Users</p>
            </div>
          </div>

          
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;